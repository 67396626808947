export const customFunctions = {
  title: "Custom Functions",
  create: "Custom Function anlegen",
  name: "Name",
  parameterName: "Name",
  return_type: "Datentyp",
  return_is_nullable: "Null",
  type: "Datentyp",
  default: "Standardwert",
  nullable: "Null",
  string: "String",
  number: "Nummer",
  boolean: "Boolean",
  object: "Objekt",
  projects: "Projekte",
  parameters: "Parameter",
  parametersAdd: "Parameter hinzufügen",
  table: {
    name: "Name"
  },
  insertVariable: 'Parameter "{name}" an aktueller Stelle einfügen',
  edit: 'Custom Function "{name}" bearbeiten',
  variables: "Parameter",
  saved: "Custom Function erfolgreich gespeichert",
  test: "Custom Function testen",
  testExecute: "Testen",
  delete: "Custom Function löschen?",
  deleted: 'Die Custom Function "{name}" wurde erfolgreich gelöscht',
  tested: "Erfolgreich getestet",
  testResult: "Vorschau",
  parametersHelp:
    "Du kannst auf die Parameter klicken, um den ausgewählten Parameter an der aktuellen Stelle einzufügen.",
  error:
    "Folgender Fehler ist beim Ausführen des JavaScript Codes aufgetreten: {error}"
};
