export const menu = {
  adminAccount: "Konto",
  adminAccountData: "Unternehmensdaten",
  adminAccountUsers: "Benutzerverwaltung",
  adminNews: "News",
  adminNotifications: "Benachrichtigungen",
  adminPartners: "Partnerverwaltung",
  adminPartnersOverview: "Übersicht",
  adminSettings: "Einstellungen",
  adminSettingsSystem: "System",
  adminSettingsServer: "Server",
  adminSettingsBilling: "Abrechnung",
  adminSettingsEmail: "E-Mail",
  adminSettingsNewsletter: "Newsletter",
  adminSettingsExtensions: "Erweiterungen",
  adminSettingsPlugins: "Plugins",
  adminTenants: "Mandantenverwaltung",
  adminTenantsOverview: "Übersicht",
  analysis: "Analyse",
  analysisJobs: "Jobs",
  analysisJournal: "Journal",
  analysisNotifications: "Benachrichtungen",
  analysisOverview: "Übersicht",
  analysisQueues: "Queues",
  analysisWorkflows: "Workflows",
  analysisWorkflowsLogs: "Logs",
  areaAdmin: "Adminbereich",
  areaPartner: "Partnerbereich",
  areaPlaceholder: "Bereich auswählen",
  areaProject: "Projektbereich",
  areaTenant: "Mandantenbereich",
  areaChange: "Bereich wechseln",
  help: "Hilfe",
  helpCenter: "Hilfecenter",
  helpContactPerson: "Ansprechpartner",
  helpDocumentation: "Dokumentation",
  helpSupport: "Support",
  logout: "Abmelden",
  partnerAccount: "Konto",
  partnerAccountAccounting: "Buchhaltung",
  partnerAccountContracts: "Verträge",
  partnerAccountData: "Unternehmensdaten",
  partnerAccountUsers: "Benutzerverwaltung",
  partnerLeads: "Leads",
  partnerProfile: "Partnerprofil",
  partnerProfileCertificates: "Zertifikate",
  partnerProfileContact: "Ansprechpartner",
  partnerProfileCredentials: "Referenzen",
  partnerProfileInformation: "Profilinformationen",
  partnerProfileServices: "Leistungen",
  partnerTenants: "Mandantenverwaltung",
  partnerTenantsOverview: "Übersicht",
  partnerSettings: "Einstellungen",
  projects: "Projekte",
  projectFileExplorer: "Datei-Explorer",
  projectInsights: "Insights",
  projectIntegrations: "Integrationen",
  projectIntegrationsOverview: "Übersicht",
  projectDynamicModelsDynamicModel: "Dynamic Models",
  projectDataStructures: "Datenstrukturen",
  projectMappings: "Mappings",
  projectMappingsMappings: "Mappings",
  projectMappingsAssignments: "Zuordnungstabelle",
  projectTextTemplates: "Text-Templates",
  projectWorkflows: "Workflows",
  projectCustomFunctions: "Custom Functions",
  projectJournal: "Journal",
  projectCommunication: "Mail Archiv",
  projectDataLake: "Datalake",
  projectDataStore: "Data-Store",
  projectDataExplorer: "Data-Explorer",
  projectDataSets: "Data-Sets",
  projectReporting: "Reporting",
  projectReportingJobs: "Jobs",
  projectSettings: "Einstellungen",
  projectSettingsAuthentication: "Authentifizierung",
  projectSettingsDataStructure: "Datenstrukturen",
  projectSettingsExtensions: "Erweiterungen",
  projectSettingsMails: "E-Mails",
  projectSettingsProjects: "Projekte",
  projectSettingsQueues: "Queues",
  projectSettingsRoles: "Rollen",
  projectSettingsResources: "Ressourcen",
  projectSettingsMailTemplates: "E-Mail Empfänger",
  projectSettingsMailSettings: "E-Mail Einstellungen",
  projectSettingsComponents: "Komponenten",
  projectSettingsConfiguration: "Konfigurationswerte",
  projectSettingsWorkflowToken: "Token",
  projectSettingsPresets: "Preset Konfigurationswerte",
  projectNotes: "Notizen",
  projectsOverview: "Übersicht",
  tenantAccounting: "Buchhaltung",
  tenantAccountingInformation: "Allgemeine Informationen",
  tenantAccountingAddress: "Rechnungsadresse",
  tenantAccountingInvoices: "Rechnungsarchiv",
  tenantAccountingPayments: "Zahlungsarten",
  tenantAccountingTicketing: "Buchungsübersicht",
  tenantContracts: "Verträge",
  tenantContractsDocuments: "Vertragsdokumente",
  tenantContractsStatus: "Vertragsstatus",
  tenantData: "Unternehmensdaten",
  tenantUserAdministration: "Benutzerverwaltung",
  tenantSettings: "Einstellungen",
  tools: "Tools",
  toolsDataLake: "Datalake",
  toolsDataLakeDynamicModels: "Dynamic Models",
  toolsDataStore: "Datastore",
  toolsMarketplaceConnection: "Marktplatzanbindung",
  toolsOrderManagement: "Ordermanagement",
  welcome: "Willkommen",
  toolsQueryEditor: "Query-Editor",
  workflows: "Workflows",
  workflowsComponents: "Komponenten",
  workflowsConnectors: "Konnektoren",
  workflowsDesigner: "Designer",
  workflowsDynamicModels: "Dynamic Models",
  workflowsMapping: "Mapping",
  workflowsOverview: "Übersicht",
  workflowsStaging: "Staging",
  adminSettingsCountries: "Länder",
  adminSettingsMailTemplates: "E-Mail Vorlagen",
  adminSettingsWorkflowElements: "Workflow Elemente",
  adminMediaManagement: "Medienverwaltung",
  adminSettingsDataStructures: "Datenstrukturen",
  adminSettingsDataStore: "Datastore",
  account: "Account",
  project: "Projekt",
  version: "Version {version}",
  xentralUrl: "Xentral Instanz"
};
