import ProcessManager from "@/components/Workflows/processManager";
import Project from "@/components/Settings/Projects/project";
import i18n from "@/core/plugins/vue-i18n";
import { Error, Toast } from "@/core/plugins/swal";
import { UPDATE_SELECTED_PROJECT } from "@/core/services/store/switchArea.module";
import { computed, ref } from "vue";
import { loadProjects } from "@/composables/useSalesChannelManagementApp";
import { useStore } from "@/core/services/store";
import { getSubdomain } from "@/core/services/api.service";
import { useAppSave } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppSave";
import { useMigration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigration";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import Presets from "@/components/Settings/Presets/presets";

const rawChannels = ref([]);
const store = useStore();
const show = ref(false);
const selectedChannelId = ref(null);
const createdProject = ref({});
const step = ref(1);
const error = ref();
const migrationErrorParams = ref();

const LOAD_CHANNELS_WORKFLOW_ID = store.getters.loadChannelsWorkflowId;
const MIGRATION_SHOP_IMPORTER_WORKFLOW_ID = computed(
  () => store.getters.environmentVariables.shopImporterWorkflowId
);
const licenseIds = store.getters.shopImporterLicenseIds?.split(",") ?? [];
const shops = store.getters.shopImporterShopsGA?.split(",") ?? [];
const isMigrating = ref(false);

export const useMigrationIntegration = () => {
  const { setProductSelectionValue } = useAppSave();
  const { setMigrationChecksConfigValue } = useMigration();
  const { integrationsWithPresets } = useIntegration();

  const channels = computed(() =>
    rawChannels.value.filter(
      c =>
        !integrationsWithPresets.value.find(
          integration =>
            ("uuid" in (integration.salesChannel?.value || {}) &&
              integration.salesChannel?.value?.uuid === c.uuid) ||
            ("id" in (integration.salesChannel?.value || {}) &&
              integration.salesChannel?.value?.id === c.id)
        )
    )
  );
  const shopImporterActive = computed(() =>
    licenseIds.includes(getSubdomain(window.location.origin))
  );

  const shopActive = computed(() => shops.includes(store.getters.appType));

  const selectedChannel = computed(
    () =>
      channels.value?.find(
        c =>
          c?.id === selectedChannelId.value?.channelId ||
          c?.uuid === selectedChannelId.value?.channelId
      ) ?? {}
  );

  async function startMigration(
    params,
    shouldCreateProject = true,
    useErrorParams = false
  ) {
    isMigrating.value = true;
    error.value = undefined;

    if (shouldCreateProject) {
      await createProject();
    }

    if (!Object.keys(createdProject.value || {}).length > 0) {
      resetValues();
      isMigrating.value = false;

      return;
    }

    const paramsToUse = useErrorParams
      ? migrationErrorParams.value ?? params
      : params;

    await migrateIntegration(paramsToUse);

    if (!error.value) {
      await setMigrationChecksConfigValue(createdProject.value.id);

      if (paramsToUse.selectionStrategy === "adoptExisting") {
        await setProductSelectionValue(createdProject.value.id);
      }
    }

    await loadProjects();
    if (!error.value) {
      resetValues(false);
    }

    step.value = 4;
    isMigrating.value = false;
  }
  async function loadChannelsForShop() {
    if (!shopActive.value && !shopImporterActive.value) {
      return;
    }

    let optionalHeaders = {
      "x-project-id": store.getters.xentralPresetProjectId
    };

    try {
      const response = await ProcessManager.run(
        LOAD_CHANNELS_WORKFLOW_ID,
        {
          dispatchImmediately: true
        },
        optionalHeaders
      );

      rawChannels.value = response.data.return.data.filter(
        c =>
          c?.type === store.getters.appType ||
          c?.modulename === store.getters.appType
      );
    } catch (error) {
      Error(error);
    }
  }
  async function createProject() {
    if (!selectedChannel.value) {
      return Toast.fire({
        icon: "warning",
        title: i18n.t("salesChannelManagementApp.migration.noChannelSelected")
      });
    }

    const baseProjectName =
      store.getters.selectedApp.name +
      " " +
      (selectedChannel.value?.name ?? selectedChannel.value?.bezeichnung);

    let duplicatedProjectName = baseProjectName;
    let counter = 1;

    // Use a loop to find a valid name
    while (
      store.getters.integrations.some(
        integration => integration.name === duplicatedProjectName
      )
    ) {
      duplicatedProjectName = `${baseProjectName}-${counter}`;
      counter++;
    }

    await Project.store({
      name: duplicatedProjectName,
      preset_instance_id: store.getters.presetInstanceId,
      preset_project_id: store.getters.presetProjectId,
      preset_id: store.getters.presetId
    })
      .then(async response => {
        createdProject.value = response.data;

        await store.dispatch(UPDATE_SELECTED_PROJECT, createdProject.value);
      })
      .catch(() => {
        Toast.fire({
          icon: "error",
          title: i18n.t(
            "salesChannelManagementApp.migration.projectCreateFailure"
          )
        });
      });
  }

  async function migrateIntegration(params) {
    if (!params) return;

    params.uuid = selectedChannel.value?.id ?? selectedChannel.value?.uuid;

    try {
      const response = await ProcessManager.run(
        MIGRATION_SHOP_IMPORTER_WORKFLOW_ID.value,
        {
          dispatchImmediately: true,
          params
        }
      );

      error.value = response?.data?.return?.data;

      if (error.value) {
        migrationErrorParams.value = params;
      }
    } catch (e) {
      migrationErrorParams.value = params;
      error.value = i18n.t(
        "salesChannelManagementApp.migration.migrationFailure"
      );
    } finally {
      if (error.value) {
        // save the error state as a preset value

        if (createdProject.value?.salesChannel?.id) {
          await store.dispatch(UPDATE_SELECTED_PROJECT, createdProject.value);

          // update
          Presets.update(createdProject.value.salesChannel.id, {
            name: "salesChannel",
            label: "salesChannel",
            type: "json",
            value: {
              ...(createdProject.value?.salesChannel?.value || {}),
              migrationError: {
                ...(createdProject.value?.salesChannel?.value?.migrationError ||
                  {}),
                message: error.value
              }
            },
            project_id: createdProject.value.id
          });
        } else {
          const storeResponse = await Presets.store({
            name: "salesChannel",
            label: "salesChannel",
            type: "json",
            value: {
              migrationError: {
                message: error.value,
                params: params
              }
            },
            project_id: createdProject.value.id
          });

          createdProject.value = {
            ...createdProject.value,
            salesChannel: storeResponse.data
          };
        }
      }
    }
  }

  function resetValues(hideModal = true) {
    createdProject.value = {};
    selectedChannelId.value = null;
    error.value = null;
    migrationErrorParams.value = null;
    if (!hideModal) return;
    show.value = false;
  }

  return {
    MIGRATION_SHOP_IMPORTER_WORKFLOW_ID,
    shopImporterActive,
    shopActive,
    show,
    store,
    channels,
    rawChannels,
    selectedChannel,
    selectedChannelId,
    isMigrating,
    step,
    createdProject,
    error,
    migrationErrorParams,
    resetValues,
    createProject,
    loadChannelsForShop,
    startMigration
  };
};
