import ApiService from "@/core/services/api.service";
import { BaseService } from "@/components/Tools/baseService";

const url = process.env.VUE_APP_API_ADMIN;
const baseUrl = "custom_functions";

class CustomFunctions extends BaseService {
  get(id) {
    return ApiService.get(url, `${baseUrl}/${id}`, this.optionalHeaders);
  }

  getAll(params = {}, filter = []) {
    let filterParams = this.getParams(filter, params);
    let options = "?" + new URLSearchParams(params).toString();
    if ("order" in params) {
      options += "&orderBy[]=" + params.order;
    }

    return ApiService.get(
      url,
      `${baseUrl}${options}${filterParams}`,
      this.optionalHeaders
    );
  }

  store(data) {
    return ApiService.post(`${url}/${baseUrl}`, data, this.optionalHeaders);
  }

  update(id, data) {
    return ApiService.put(
      `${url}/${baseUrl}/${id}`,
      data,
      this.optionalHeaders
    );
  }

  delete(id) {
    return ApiService.delete(`${url}/${baseUrl}/${id}`, this.optionalHeaders);
  }

  execute(id, customFunction) {
    return ApiService.post(
      `${url}/${baseUrl}/execute`,
      customFunction,
      this.optionalHeaders
    );
  }
}

export default new CustomFunctions();
