export const productSelectionVariables = [
  {
    text: "Artikel ID",
    name: "id"
  },
  {
    text: "Artikelkategorie ID",
    name: "merchandiseGroup"
  },
  {
    text: "Artikel (DE)",
    name: "name"
  },
  {
    text: "Artikel Nr.",
    name: "number"
  },
  {
    text: "Projekt ID",
    name: "project"
  },
  {
    text: "Projekt Name",
    name: "projectName"
  },
  {
    text: "EAN Nr. / Barcode",
    name: "ean"
  },
  {
    text: "Sperre aktiv",
    type: "boolean",
    name: {
      false: "onlyActive",
      true: "onlyLocked"
    }
  },
  {
    text: "Online-Shop UUID",
    name: "salesChannel"
  },
  {
    text: "Hersteller",
    name: "manufacturerName"
  },
  {
    text: "Hersteller Nr.",
    name: "manufacturerNumber"
  },
  ...getFreeFields()
];

function getFreeFields(length = 40) {
  const freeFields = [];
  for (let i = 1; i <= length; i++) {
    freeFields.push({
      text: `Freifeld ${i}`,
      name: `freeField${i}`
    });
  }
  return freeFields;
}
