<template>
  <div>
    <div v-if="!node">
      <span>{{ $t("workflowDesigner.selectWidget") }}</span>
    </div>
    <div v-else>
      <FormHelper
        v-model="values"
        :form="form"
        :config="formConfig"
        @change="onChange"
        @action="onAction"
      />
      <div v-if="elementSelectorField" class="form-group">
        <ElementSelector
          :field="elementSelectorField"
          :select-multiple="elementSelectorField.selectMultiple ?? false"
          :allow-only="elementSelectorField.allowOnly ?? []"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { addAreaPrefix } from "@/components/Workflows/Designer/Canvas/Configuration/functions";
import { generateHash } from "@/components/Tools/helperFunctions";
import { bus } from "@/main";
import _ from "lodash";
import ElementSelector from "@/components/Workflows/Designer/Canvas/Components/FieldHelperFields/ElementSelector.vue";
export default {
  components: { ElementSelector, FormHelper },
  props: ["node", "configValues", "outputValues", "debugValues", "parameters"],
  data() {
    return {
      areas: ["authentication", "configuration", "input", "output", "error"],
      formConfig: {
        snippetPrefix: this.snippetPrefix(),
        labelStacked: true,
        enableVariables: true,
        customVariables: ["outputValues", "parameters"],
        distinctVariables: true,
        enableTypecast: true
      }
    };
  },
  computed: {
    values: {
      get() {
        let values = {};
        this.areas.forEach(area => (values[area] = {}));
        Object.keys(this.node.attrs.data).forEach(area => {
          if (!this.areas.includes(area)) {
            return;
          }
          this.node.attrs.data[area].forEach(field => {
            let value = field.value;
            if (
              this.node.attrs.name === "branch" &&
              field.name === "conditions"
            ) {
              value = value.slice(0, -1);
            }
            values[area][field.name] = value;
          });
        });
        return _.cloneDeep(values);
      },
      set(data) {
        let config = _.cloneDeep(this.node.attrs.data.configuration),
          values = _.cloneDeep(data);

        Object.keys(values.configuration).forEach(key => {
          const codeField = this.codeFields?.find(f => f.name === key);
          if (codeField) return;

          let field = config.find(f => f.name === key);
          let value = values.configuration[key];
          if (
            this.node.attrs.name === "branch" &&
            field.name === "conditions"
          ) {
            let conditionsField = config.find(f => f.name === "conditions");
            value.push(conditionsField.value[conditionsField.value.length - 1]);
          }
          field.value = value;
        });
        this.node.attrs.data.configuration = config;
      }
    },
    codeFields: function () {
      return this.node?.attrs?.data?.configuration?.filter(
        f => f.type === "code"
      );
    },
    form: function () {
      let form =
        this.node?.attrs?.data?.configuration
          ?.filter(f => f.type !== "elementSelector")
          ?.map(f => (f.type === "code" ? { ...f, disabled: true } : f)) ?? [];

      return addAreaPrefix(form, "configuration");
    },
    elementSelectorField: function () {
      return this.node?.attrs.data.configuration.find(
        f => f.type === "elementSelector"
      );
    }
  },
  mounted() {},
  methods: {
    snippetPrefix() {
      return (
        "workflowElements." +
        this.$store.getters.getElementByName(
          this.node.attrs.data.flow_element_name
        ).name
      );
    },
    onChange(payload) {
      this.$emit("change");
      this.$nextTick().then(() => {
        if (
          this.node.attrs.name === "branch" &&
          payload.name === "configuration.conditions"
        ) {
          if (payload.valuePath !== "" && !isNaN(payload.valuePath)) {
            if (payload.remove) {
              this.removeBranch(payload.value.id);
            } else if (payload.move) {
              this.moveBranch(
                payload.value.id,
                payload.valuePath,
                payload.newIndex
              );
            } else {
              this.addBranch(payload.valuePath);
            }
          } else if (payload.valuePath.includes("label")) {
            let emitPayload = {
              node: this.node,
              dataKey: "configuration",
              elementName: "conditions"
            };
            bus.$emit("refresh-branch-label", emitPayload);
          }
        }
        this.checkOnChangeAction(payload);
        this.checkBatchChange(payload);
      });
    },
    checkOnChangeAction(payload) {
      let field = this.node.attrs.data.configuration.find(
        f => "configuration." + f.name === payload.name
      );
      if (!field.onChange) {
        return;
      }
      this.$nextTick().then(() => {
        bus.$emit(
          "fireAction",
          {
            name: field.onChange,
            label: field.onChange
          },
          false
        );
      });
    },
    checkBatchChange(payload) {
      if (
        payload.name === "configuration.batchSize" ||
        payload.name === "configuration.limit"
      ) {
        this.$nextTick().then(() => {
          bus.$emit("csvBatchBatchSizeChanged");
        });
      }
    },
    onAction(actionName) {
      let field = this.form.find(field => field.action === actionName);
      bus.$emit("fireAction", field, false);
    },
    addBranch(index) {
      let id = generateHash();
      let condition = this.node.attrs.data.configuration.find(
        el => el.name === "conditions"
      ).value[index];
      this.$set(condition, "id", id);
      let payload = {
        node: this.node,
        id: id
      };
      bus.$emit("add-condition", payload);
    },
    removeBranch(id) {
      const payload = {
        node: this.node,
        id: id
      };
      bus.$emit("remove-condition", payload);
    },
    moveBranch(id, oldIndex, newIndex) {
      const payload = {
        node: this.node,
        id: id,
        oldIndex: oldIndex,
        newIndex: newIndex
      };
      bus.$emit("move-condition", payload);
    }
  }
};
</script>

<style lang="scss"></style>
