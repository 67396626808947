<template>
  <div>
    <Header
      :items="headerButtons"
      :show-back-button="true"
      :title="title"
      @back="$router.push({ name: 'projectDataExplorer' })"
      @open-editor="openEditor"
    />
    <Preview
      v-if="ready && dataStructure !== []"
      :data-structure="dataStructure"
    />
  </div>
</template>

<script>
import DataStructures from "@/components/DataStructures/dataStructures";
import QueryEditor from "@/components/DataExplorer/queryEditor";
import Preview from "@/components/DataExplorer/Preview.vue";
import Header from "@/components/Tools/Header/Header.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";

export default {
  components: { Header, Preview },
  props: {},
  data() {
    return {
      id: this.$route.params.id,
      dataStructure: undefined,
      tabIndex: 0,
      ready: false,
      headerButtons: [
        {
          type: "button",
          tooltip: this.dataStructure?.owner_type.includes("DataSet")
            ? this.$t("dataExplorer.editDataSet")
            : this.$t("dataExplorer.editDataStructure"),
          icon: "fal fa-paint-brush",
          emit: "open-editor"
        }
      ]
    };
  },
  computed: {
    title: function () {
      return this.$t("dataExplorer.dataStructure") + " - " + this.sourceLabel;
    },
    sourceLabel: function () {
      if (!this.dataStructure) {
        return "";
      }
      return this.dataStructure.label
        ? this.dataStructure.label
        : this.dataStructure.classname;
    }
  },
  async mounted() {
    await this.loadData();
    this.ready = true;
  },
  methods: {
    openEditor() {
      if (this.dataStructure.owner_type.includes("DataSet")) {
        this.$router.push({
          name: "projectDataSetsEditor",
          params: { id: this.dataStructure.owner_id }
        });
        return;
      }
      this.$router.push({
        name: "projectDataStructuresEditor",
        params: { id: this.id }
      });
    },
    async loadData() {
      this.entries = [];
      await this.loadDataStructure();
    },
    async loadDataStructure() {
      addEventToLoadingQueue({ key: "loadDataStructure" });
      await DataStructures.get(this.id)
        .then(response => {
          this.dataStructure = response.data;
          if (!this.dataStructure.owner_type.includes("DataSet")) {
            this.dataStructure.fields = response.data.fields.filter(
              e =>
                !!e.show_in_view ||
                e.name === this.dataStructure.primary.replace("_", "")
            );
          }
          removeEventFromLoadingQueue({ key: "loadDataStructure" });
        })
        .catch(error => {
          this.$error(error);
          removeEventFromLoadingQueue({ key: "loadDataStructure" });
        });
    },
    async loadDataSet() {
      addEventToLoadingQueue({ key: "loadDataSet" });
      addEventToLoadingQueue({ key: "getCollectionInfo" });
      await QueryEditor.load(this.id)
        .then(response => {
          this.dataSet = response.data;
          removeEventFromLoadingQueue({ key: "loadDataSet" });
        })
        .catch(error => {
          this.$error(error);
          removeEventFromLoadingQueue({ key: "loadDataSet" });
        });
      await QueryEditor.getCollectionInfo(this.id)
        .then(response => {
          this.dataStructure = response.data;
          removeEventFromLoadingQueue({ key: "getCollectionInfo" });
        })
        .catch(error => {
          this.$error(error);
          removeEventFromLoadingQueue({ key: "getCollectionInfo" });
        });
    }
  }
};
</script>
