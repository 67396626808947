import _ from "lodash";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import i18n from "@/core/plugins/vue-i18n";
import Presets from "@/components/Settings/Presets/presets";
import {
  resetDeactivatedFeatures,
  resetValuesToSave
} from "@/composables/useSalesChannelManagementApp";
import { Error, Toast } from "@/core/plugins/swal";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useSaveFormatter } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useSaveFormatter";
import { useStore } from "@/core/services/store";
import { useProductSelection } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useProductSelection";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";
import { useMigration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigration";
import Config from "@/components/Settings/Config/config";
import { LOAD_CONFIG_VALUES } from "@/core/services/store/variables_v1.module";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import AuthValues from "@/components/Settings/Auth/authValues";

export const useAppSave = () => {
  const store = useStore();
  const { hasMigration } = useAppStatus();
  const { features, selectedFeature, activationDatesToSet } = useFeatures();
  const { selectedArea } = useArea();
  const { selectedIntegration, formValueChange } = useIntegration();
  const { saveFormatter } = useSaveFormatter();
  const { hasValidEntry } = useProductSelection();
  const { handleMigrationOnSave } = useMigration();

  /**
   * Save the preset values
   * @returns {Promise<void>}
   */
  async function save(silent = false) {
    handleDeactivatedFeatures();

    const valuesToSave = store.getters.valuesToSave;

    if (
      features.value.length === 0 &&
      !!(store.getters.form("wizard.features") || []).length
    ) {
      await showFeatureRequiredWarning();
      return;
    }

    addEventToLoadingQueue({
      key: "saveApp",
      group: "salesChannelManagementApp"
    });

    if (selectedFeature.value === "settings" && hasMigration.value) {
      await handleMigrationOnSave();
    }

    if (Object.keys(valuesToSave || {}).length === 0) {
      await finalizeSaveProcess(silent);

      return;
    }

    try {
      await processSaveRequests(valuesToSave);
    } catch (error) {
      Error(error);
    } finally {
      await finalizeSaveProcess(silent);
    }
  }

  async function processSaveRequests(valuesToSave) {
    const saveRequests = [];
    const filteredValuesToSave = Object.keys(valuesToSave).filter(
      key => key !== "productSelection" && key !== "migration"
    );

    for (const featureName of filteredValuesToSave) {
      const val = valuesToSave[featureName];

      if (typeof val !== "object") continue;

      if (val.id !== undefined) {
        saveRequests.push(updateArea(val));
        continue;
      }

      for (const areaName of Object.keys(val)) {
        await processArea(valuesToSave, saveRequests, featureName, areaName);
      }

      if (!features.value.includes(featureName)) {
        delete valuesToSave[featureName];
      }
    }

    await handleMigrationSave(valuesToSave, saveRequests);
    await handleProductSelectionSave(valuesToSave, saveRequests);

    await Promise.all(saveRequests);
  }

  function handleDeactivatedFeatures() {
    const deactivatedFeatures = store.getters.deactivatedFeatures;

    for (const deactivatedFeature of deactivatedFeatures) {
      const payload = {
        name: "active",
        value: false
      };
      formValueChange(payload, deactivatedFeature, "baseSettings");
    }

    resetDeactivatedFeatures();
  }

  async function handleMigrationSave(valuesToSave, saveRequests) {
    const migrationVal = valuesToSave?.migration;

    if (migrationVal) {
      if (migrationVal?.id) {
        saveRequests.push(updateArea(migrationVal));
      } else {
        const response = await storeArea(migrationVal);
        selectedIntegration.value.migration.id = response.data.id;
      }
    }
  }

  async function handleProductSelectionSave(valuesToSave, saveRequests) {
    const productSelectionAreas = valuesToSave?.productSelection;

    for (const productSelectionName of Object.keys(
      productSelectionAreas || {}
    )) {
      const productSelectionArea = _.cloneDeep(
        productSelectionAreas[productSelectionName]
      );

      let valid = hasValidEntry(productSelectionArea?.value?.condition || {});

      if (!valid) {
        productSelectionArea.value.condition = null;
      }

      if (productSelectionArea.value) {
        await formatAreaValues(
          productSelectionArea,
          "productSelection",
          productSelectionName,
          ""
        ).then(response => {
          productSelectionArea.value = response;
        });
      }

      if (productSelectionArea.id) {
        saveRequests.push(updateArea(productSelectionArea));
      } else {
        const response = await storeArea(productSelectionArea);
        selectedIntegration.value.productSelection[productSelectionName].id =
          response.data.id;
      }
    }
  }

  async function processArea(
    valuesToSave,
    saveRequests,
    featureName,
    areaName
  ) {
    let area = _.cloneDeep(valuesToSave[featureName][areaName]);

    if (
      areaName === "baseSettings" &&
      featureName === "salesOrder" &&
      activationDatesToSet.value.includes(featureName)
    ) {
      area.value["activatedAt"] = new Date();
    }

    if (typeof area === "object" && area.value) {
      area.value = await formatAreaValues(area, featureName, areaName);
    }

    if (area.id === undefined) {
      const response = await storeArea(area);
      valuesToSave[featureName][areaName].id = response.data.id;
    } else {
      saveRequests.push(updateArea(area));
    }
  }

  async function finalizeSaveProcess(silent = false) {
    await resetValuesToSave();

    if (
      selectedFeature.value === "productSelection" &&
      selectedArea.value === "selection"
    ) {
      await handleProductSelection();
    }

    let payload = {
      group: "salesChannelManagementApp"
    };

    if (!silent) {
      payload = {
        ...payload,
        type: "success",
        prefix: "salesChannelManagementApp",
        name: "appSaved",
        data: {
          integrationName: selectedIntegration.value.name.replace(
            store.getters.selectedApp.name,
            ""
          )
        }
      };
    }

    removeEventFromLoadingQueue(payload);
  }

  async function setProductSelectionValue(id) {
    let productsSelected =
      store.getters["variables/configValueByName"]("productsSelected");

    if (!productsSelected) {
      await storeProductsSelected(id);
    } else {
      await updateProductsSelected(id);
    }

    await store.dispatch("variables/" + LOAD_CONFIG_VALUES);
  }

  async function handleProductSelection() {
    if (selectedIntegration?.value?.productsSelected?.id) {
      return;
    }

    await setProductSelectionValue(selectedIntegration.value.id);
  }

  async function updateProductsSelected(id) {
    let productsSelectedConfig =
      store.getters["variables/configValueByName"]("productsSelected");

    await Config.update(productsSelectedConfig.id, {
      name: "productsSelected",
      label: "productsSelected",
      type: "json",
      value: {
        ...productsSelectedConfig.value,
        ...{
          [id]: true
        }
      }
    });
  }

  async function storeProductsSelected(id) {
    await Config.store({
      name: "productsSelected",
      label: "productsSelected",
      type: "json",
      value: {
        [id]: true
      }
    });
  }

  async function showFeatureRequiredWarning() {
    await Toast.fire({
      icon: "warning",
      title: i18n.t("salesChannelManagementApp.featureRequired")
    });
  }

  async function formatAreaValues(
    area,
    featureName,
    areaName,
    formBasePath = "app."
  ) {
    for (const fieldName of Object.keys(area.value)) {
      const fieldPath = `${featureName}.${areaName}`;
      const currentForm = store.getters.form(`${formBasePath}${fieldPath}`);
      let currentValue = area.value[fieldName];
      let currentField = currentForm?.find(el => el.name === fieldName);
      if (currentField?.saveFormatter) {
        area.value[fieldName] = await saveFormatter(
          currentField.saveFormatter,
          currentField,
          currentValue,
          fieldPath
        );
      }
    }
    return area.value;
  }

  function storeArea(area) {
    if (area === "credentials") {
      return;
    }
    return Presets.store(area, true);
  }

  function updateArea(area) {
    if (area.name === "baseData") {
      handleActivation(area);
    }

    if (area.name === "credentials") {
      AuthValues.update(store.getters.credentialIds.authValue, area).catch(
        async () => {
          const response = await AuthValues.store(area);
          await store.dispatch("setCredentialIds", {
            key: "authValue",
            id: response.data.id
          });
        }
      );
      const presetValueId = store.getters.credentialIds.presetValue;
      area.type = area?.type ?? "json";
      area.project_id =
        area?.project_id ?? store.getters.selectedIntegration.id;
      return Presets.update(presetValueId, area);
    }

    return Presets.update(area.id, area);
  }

  function handleActivation(area) {
    if (!area.value.active) return;

    const date = new Date();
    selectedIntegration.value.baseData.value.activatedAt = date;
    area.value["activatedAt"] = date;
  }

  return { save, setProductSelectionValue, storeArea };
};
