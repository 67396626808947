import { admin } from "./components/admin";
import { assignments } from "./components/assignments";
import { user } from "./components/user";
import { billing } from "./components/billing";
import { client } from "./components/client";
import { companyData } from "./components/companyData";
import { config } from "./components/config";
import { countries } from "./components/countries";
import { dataStructures } from "./components/dataStructures";
import { dynamicModels } from "./components/dynamicModels";
import { emailLog } from "./components/emailLog";
import { emailSettings } from "./components/emailSettings";
import { error } from "./components/error";
import { fileExplorer } from "@/i18n/en-us/components/fileExplorer";
import { general } from "./components/general";
import { integrations } from "./components/integrations";
import { journal } from "./components/journal";
import { languages } from "./components/languages";
import { login } from "./components/login";
import { mailRecipient } from "./components/mailRecipient";
import { mailTemplates } from "./components/mailTemplates";
import { media } from "./components/media";
import { menu } from "./components/menu";
import { news } from "./components/news";
import { notifications } from "./components/notifications.js";
import { partner } from "./components/partner";
import { pagination } from "@/i18n/en-us/components/pagination";
import { processCodeEditor } from "./components/processCodeEditor";
import { processConfiguration } from "./components/processConfiguration";
import { processManager } from "./components/processManager";
import { processStatus } from "./components/processStatus";
import { processes } from "./components/processes";
import { projects } from "./components/projects.js";
import { queryEditor } from "./components/queryEditor";
import { queuedJobs } from "./components/queuedJobs";
import { queues } from "./components/queues";
import { reporting } from "./components/reporting";
import { role } from "./components/role";
import { scheduleMethods } from "./components/scheduleMethods";
import { scheduleParams } from "./components/scheduleParams";
import { search } from "./components/search";
import { serverInfo } from "./components/server_info";
import { settings } from "./components/settings";
import { storage } from "./components/storage";
import { table } from "./components/table";
import { tenantConnections } from "./components/tenantConnections";
import { tool } from "./components/tool";
import { topbar } from "./components/topbar";
import { users } from "./components/users";
import { usersGrantIps } from "./components/userGrantIps";
import { validation } from "./components/validation";
import { weekdays } from "./components/weekdays";
import { workflowDesigner } from "./components/workflowDesigner";
import { workflowElements } from "./components/workflowElements";
import { communication } from "./components/communication";
import { workflowToken } from "./components/workflowToken";
import { support } from "./components/support";
import { mapping } from "./components/mapping";
import { processLog } from "./components/process_log";
import { grantedIps } from "@/i18n/en-us/components/grantedIps";
import { workflowComments } from "@/i18n/en-us/components/workflowComments";
import { plugins } from "@/i18n/en-us/components/plugins";
import { dataExplorer } from "@/i18n/en-us/components/dataExplorer";
import { dataSets } from "@/i18n/en-us/components/dataSets";
import { insights } from "@/i18n/en-us/components/insights";
import { textTemplates } from "./components/textTemplates";
import { formHelper } from "@/i18n/en-us/components/formHelper";
import { tableHelper } from "@/i18n/en-us/components/tableHelper";
import { variables } from "@/i18n/en-us/components/variables";
import { formHelperFactory } from "@/i18n/en-us/components/formHelperFactory";
import { presets } from "@/i18n/en-us/components/presets";
import { salesChannelManagementApp } from "@/i18n/en-us/components/salesChannelManagementApp/salesChannelManagementApp";
import { watchDog } from "@/i18n/en-us/components/watchDog";
import { reportExports } from "@/i18n/en-us/components/reportExports";
import { workNotes } from "@/i18n/en-us/components/workNotes";
import { customFunctions } from "@/i18n/en-us/components/customFunctions";

export const locale = {
  admin: admin,
  assignments: assignments,
  user: user,
  billing: billing,
  client: client,
  companyData: companyData,
  config: config,
  countries: countries,
  customFunctions: customFunctions,
  dataStructures: dataStructures,
  dynamicModels: dynamicModels,
  emailLog: emailLog,
  emailSettings: emailSettings,
  error: error,
  fileExplorer: fileExplorer,
  general: general,
  integrations: integrations,
  journal: journal,
  languages: languages,
  login: login,
  mailRecipient: mailRecipient,
  mailTemplates: mailTemplates,
  media: media,
  menu: menu,
  news: news,
  notifications: notifications,
  partner: partner,
  processCodeEditor: processCodeEditor,
  processConfiguration: processConfiguration,
  processManager: processManager,
  processStatus: processStatus,
  processes: processes,
  projects: projects,
  queryEditor: queryEditor,
  queuedJobs: queuedJobs,
  queues: queues,
  reporting: reporting,
  role: role,
  returnValues: "return values",
  scheduleMethods: scheduleMethods,
  scheduleParams: scheduleParams,
  search: search,
  serverInfo: serverInfo,
  settings: settings,
  storage: storage,
  table: table,
  tenantConnections: tenantConnections,
  tool: tool,
  topbar: topbar,
  users: users,
  usersGrantIps: usersGrantIps,
  validation: validation,
  weekdays: weekdays,
  workflowDesigner: workflowDesigner,
  workflowElements: workflowElements,
  communication: communication,
  workflowToken: workflowToken,
  support: support,
  mapping: mapping,
  processLog: processLog,
  insights: insights,
  grantedIps: grantedIps,
  dataExplorer: dataExplorer,
  dataSets: dataSets,
  workflowComments: workflowComments,
  plugins: plugins,
  pagination: pagination,
  textTemplates: textTemplates,
  formHelper: formHelper,
  variables: variables,
  tableHelper: tableHelper,
  formHelperFactory: formHelperFactory,
  salesChannelManagementApp: salesChannelManagementApp,
  presets: presets,
  watchDog: watchDog,
  reportExports: reportExports,
  workNotes: workNotes
};
