import { productSelectionVariables } from "@/components/ExternalApps/SalesChannelManagementApp/utility/productSelectionVariables";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";
import _ from "lodash";

/**
 * @typedef {Object} ItemSwapConfig
 * @property {string} variableKey
 * @property {string} key
 * @property {string} operatorKey
 */

export const useConditions = () => {
  const { selectedIntegration } = useIntegration();
  const { selectedArea } = useArea();

  /**
   * @param {object} payload
   */
  function resolveConditions(payload) {
    const currentField =
      selectedIntegration.value.productSelection?.[selectedArea.value]?.value?.[
        payload.name
      ];

    resolveConditionChildren(currentField);
  }

  /**
   * @param {object} condition
   * @param {ItemSwapConfig} source
   * @param {ItemSwapConfig} target
   */
  function swapValues(condition, source, target) {
    const {
      variableKey: sourceVariableKey,
      key: sourceKey,
      operatorKey: sourceOperatorKey
    } = source;
    const {
      variableKey: targetVariableKey,
      key: targetKey,
      operatorKey: targetOperatorKey
    } = target;
    const variables = productSelectionVariables;
    let currentVariableField = variables.find(
      variable => variable[sourceVariableKey] === condition[sourceKey]
    );

    // If there is no variable we search the variables where name is an object
    if (!currentVariableField) {
      currentVariableField = productSelectionVariables.find(
        variable =>
          typeof variable.name === "object" &&
          Object.values(variable.name).includes(condition[sourceKey])
      );
    }

    const currentVariableValue = variables.find(
      variable => variable[sourceVariableKey] === condition.value
    );

    condition[targetOperatorKey] =
      _.cloneDeep(condition[sourceOperatorKey]) ?? condition[targetOperatorKey];
    delete condition[sourceOperatorKey];

    if (currentVariableValue?.[targetVariableKey]) {
      condition.value = currentVariableValue[targetVariableKey];
    }

    if (currentVariableField?.[targetVariableKey]) {
      let field = _.cloneDeep(currentVariableField[targetVariableKey]);

      // Handle all the cases where the variable type is boolean
      if (currentVariableField.type === "boolean") {
        // Handle the cases where the field is an object of possible fields
        // This should only happen on save
        if (typeof field === "object") {
          field = field?.[condition?.value?.toString()] ?? field["false"];
          condition[targetOperatorKey] = "is";
          condition.value = 1;
        } else {
          // Handle the cases where the field is a string. In this case we have to search in the variable for the corresponding value
          // This should only happen on load
          condition.value =
            Object.keys(currentVariableField[sourceVariableKey]).find(
              key =>
                currentVariableField[sourceVariableKey][key] ===
                condition[sourceKey]
            ) === "true";
          condition[targetOperatorKey] = "equals";
        }
      }

      delete condition[sourceKey];
      condition[targetKey] = field;
    }

    if (condition?.children) {
      for (const child of condition?.children || []) {
        swapValues(child, source, target);
      }
    }
  }

  /**
   * @param {object} field
   */
  function resolveConditionChildren(field) {
    const source = {
      variableKey: "name",
      key: "key",
      operatorKey: "op"
    };
    const target = {
      variableKey: "text",
      key: "field",
      operatorKey: "operator"
    };

    for (const child of field?.children || []) {
      swapValues(child, source, target);
    }
  }

  /**
   * @param {object} field
   * @param {object|string|number|boolean|array|null} value
   * @returns {Promise<object|string|number|boolean|array|null>}
   */
  async function saveConditions(field, value) {
    if (value === null) return value;

    let returnValue = value ?? {};

    if (!Object.keys(returnValue).length) return returnValue;
    returnValue = saveConditionChildren(returnValue);

    return returnValue;
  }

  /**
   * @param {object} returnValue
   * @returns {object}
   */
  function saveConditionChildren(returnValue) {
    for (const child of returnValue?.children || []) {
      const source = {
        variableKey: "text",
        key: "field",
        operatorKey: "operator"
      };
      const target = {
        variableKey: "name",
        key: "key",
        operatorKey: "op"
      };
      swapValues(child, source, target);
    }

    return returnValue;
  }

  return { resolveConditions, saveConditions };
};
