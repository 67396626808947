<script setup>
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useFeatureReporting } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureReporting";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import Modal from "@/components/Tools/Modal.vue";
import { computed, onMounted, ref } from "vue";
import TestForm from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/TestForm.vue";
import { useTesting } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useTesting";
import TableHelperQuickFilter from "@/components/ExternalApps/SalesChannelManagementApp/components/Journal/TableHelperQuickFilter.vue";
import i18n from "@/core/plugins/vue-i18n";
import { useStore } from "@/core/services/store";

const {
  actions,
  batchActions,
  fields,
  meta,
  quickFilterModel,
  reportingData,
  reportingDetailFields,
  reportingDetailMeta,
  reportingDetailData,
  showDetailModal,
  paramPrefix,
  paramPrefixDetail,
  search,
  quickFilterParameters,
  isFiltered,
  executeDataSet,
  showDetail
} = useFeatureReporting();
const { selectedFeature } = useFeatures();
const { requeueJob, requeueJobs, BATCH_WORKFLOW_ID } = useTesting();
const store = useStore();
const allRowsSelected = ref(false);

const quickFilterFeatures = {
  salesOrder: ["state", "orderDate"],
  tracking: ["state", "orderDate", "tracking"]
};

const activeFilter = computed(() => {
  for (const value of Object.values(quickFilterModel.value)) {
    if (value) return true;
  }
  return false;
});

function resetFilter() {
  quickFilterModel.value = {};
  executeDataSet();
}

onMounted(() => executeDataSet());
</script>

<template>
  <div>
    <TableHelper
      ref="reportingTable"
      :key="'reporting-' + selectedFeature"
      :actions="actions"
      :buttons="batchActions"
      :fields="fields[selectedFeature]"
      :items="reportingData"
      :meta="meta"
      :no-route-params="true"
      :param-prefix="paramPrefix"
      :use-card="false"
      actions-fixed
      loading-key="load-reporting-data"
      progress-bar-key="load-reporting-data"
      @search="search = $event"
      @select-all-rows-in-data="allRowsSelected = $event"
      @reload-data="executeDataSet"
      @requeue-job="requeueJob"
      @requeue-jobs="
        requeueJobs(
          allRowsSelected,
          $refs.reportingTable?.selectedRows.map(row => row.orderId),
          quickFilterParameters,
          isFiltered
        )
      "
      @show-detail="showDetail"
    >
      <template v-if="quickFilterFeatures?.[selectedFeature]" #afterToolbar>
        <TableHelperQuickFilter
          v-for="feature in quickFilterFeatures?.[selectedFeature]"
          :key="feature"
          v-model="quickFilterModel"
          :feature="feature"
          @input="quickFilterModel = $event"
          @execute-filter="executeDataSet"
        ></TableHelperQuickFilter>
        <button
          v-if="activeFilter"
          class="btn btn-sm btn-hover-light-primary"
          @click="resetFilter"
        >
          <i class="fal fa-filter-slash p-0 fa-lg"></i>
        </button>
      </template>
      <template #beforeHeaderButtons>
        <TestForm
          v-if="selectedFeature === 'tracking'"
          :title="
            i18n
              .t('salesChannelManagementApp.test.exportCancelledTracking')
              .toString()
          "
          :button-text="
            i18n
              .t('salesChannelManagementApp.test.exportCancelledTracking')
              .toString()
          "
          :workflow-id="store.getters.exportCancellationsWorkflowId"
        />
        <TestForm v-if="BATCH_WORKFLOW_ID" />
      </template>
    </TableHelper>

    <Modal
      type="large"
      :show-modal="showDetailModal"
      :title="
        $t('salesChannelManagementApp.reporting.title', {
          featureName: $t('salesChannelManagementApp.titles.' + selectedFeature)
        }).toString()
      "
      @toggle-modal="showDetailModal = $event"
    >
      <template #content>
        <TableHelper
          loading-key="load-reporting-detail"
          progress-bar-key="load-reporting-detail"
          :param-prefix="paramPrefixDetail"
          :items="reportingDetailData"
          :fields="reportingDetailFields"
          :meta="reportingDetailMeta"
          :use-card="false"
          :no-route-param="true"
          :enable-select="false"
          :disable-search="true"
          :no-route-params="true"
          @reload-data="showDetail"
          @show-detail="showDetail"
        />
      </template>
    </Modal>
  </div>
</template>

<style scoped lang="scss"></style>
