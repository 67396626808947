<script setup>
import { useMigrationIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigrationIntegration";
import { computed, ref } from "vue";
import Modal from "@/components/Tools/Modal.vue";
import Loader from "@/components/Tools/Loader.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { useStore } from "@/core/services/store";
import {
  integrationFormAllIntegrations,
  integrationForms
} from "@/components/ExternalApps/SalesChannelManagementApp/utility/integrationForms";
import { labelData } from "@/components/ExternalApps/SalesChannelManagementApp/utility/labelData";
import i18n from "@/core/plugins/vue-i18n";
import { useHelp } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useHelp";
import HelpArea from "@/components/ExternalApps/SalesChannelManagementApp/components/HelpArea.vue";

const {
  show,
  channels,
  selectedChannel,
  selectedChannelId,
  isMigrating,
  step,
  error,
  startMigration
} = useMigrationIntegration();
const { getLinkForTypeAndArea } = useHelp();
const LAST_STEP = 3;
const isLastStep = computed(() => step.value === LAST_STEP);
const store = useStore();

const config = {
  snippetPrefix: "salesChannelManagementApp.migration.form",
  labelColClass: "col-5",
  fieldColClass: "col-7",
  fieldColClassXl: "col-7",
  disableSkeletonLoading: true,
  ...labelData.value
};
const configSelectForm = {
  ...config,
  disableValidations: true
};
const migrationSelectField = computed(() => [
  {
    name: "channelId",
    type: "select",
    label: "salesChannel",
    infoText: "salesChannelHelp",
    options: channels.value?.map(c => {
      return {
        label: c?.name ?? c?.bezeichnung,
        value: c?.id ?? c?.uuid
      };
    }),
    default: channels.value?.[0].id,
    validations: {
      required: true
    }
  }
]);
const appName = computed(() => store.getters.appType);
const forms = computed(() => integrationForms?.[appName.value] ?? []);
const model = ref({});
const formHelper = ref();
const formHelperSelect = ref();
const titles = computed(() => ({
  1: "salesChannelManagementApp.migration.selectIntegration",
  2: "salesChannelManagementApp.migration.formularTitle",
  3: "salesChannelManagementApp.migration.integrationSetupConfirmationTitle",
  4: error.value
    ? "salesChannelManagementApp.migration.finishedErrorTitle"
    : "salesChannelManagementApp.migration.finishedTitle"
}));
const stepTitle = computed(() =>
  isMigrating.value ? null : i18n.t(titles.value[step.value])
);
const helpItems = computed(() => [
  {
    href: getLinkForTypeAndArea("handbook", "migration", false),
    icon: "fa-book",
    label: i18n.t("salesChannelManagementApp.helpArea.handbook")
  },
  {
    href: getLinkForTypeAndArea("video", "migration", false),
    icon: "fa-play",
    label: i18n.t("salesChannelManagementApp.helpArea.video")
  },
  {
    href: getLinkForTypeAndArea("faq", "migration", false),
    icon: "fa-question",
    label: i18n.t("salesChannelManagementApp.helpArea.faq")
  }
]);
const appHandbookLink = computed(() =>
  getLinkForTypeAndArea("handbook", "listing", false)
);
const appVideoLink = computed(() =>
  getLinkForTypeAndArea("video", "listing", false)
);

/**
 * @param {number} nextStep
 * @returns {*}
 */
function switchStep(nextStep) {
  if (formHelperSelect.value?.validate() !== true) return;

  // Only validate the forms in the second step
  if (!formHelper.value || step.value !== 2) return (step.value = nextStep);
  let counter = 0;

  // Validate each form
  for (const form of formHelper.value) {
    if (form.validate() !== true) {
      counter++;
    }
  }
  // If valid go to next step
  if (counter === 0) step.value = nextStep;
}
</script>

<template>
  <Modal
    :title="stepTitle"
    :show-modal="show"
    :show-footer="!isMigrating"
    :disable-close="isMigrating"
    close-text="general.cancel"
    type="x-large"
    @toggle-modal="show = $event"
  >
    <template #beforeClose>
      <HelpArea :items="helpItems"></HelpArea>
    </template>

    <template #content>
      <div v-if="isMigrating" class="h-200px">
        <div class="alert alert-info">
          {{ $t("salesChannelManagementApp.migration.isMigrating") }}
        </div>
        <Loader v-if="isMigrating" />
      </div>

      <v-stepper v-else v-model="step" flat>
        <v-stepper-items>
          <v-stepper-content step="1" class="p-0">
            <div>
              <div>
                <div
                  v-if="
                    !!store.getters['loadingQueue/showLoadingBar'](
                      'loadChannels'
                    )
                  "
                  class="loader"
                >
                  <Loader />
                </div>
                <div v-else class="d-flex flex-wrap mt-4">
                  <div v-if="channels.length === 0">
                    {{
                      $t("salesChannelManagementApp.migration.noChannelsFound")
                    }}
                  </div>
                  <div v-else>
                    <FormHelper
                      ref="formHelperSelect"
                      v-model="selectedChannelId"
                      :form="migrationSelectField"
                      :config="configSelectForm"
                    >
                    </FormHelper>
                  </div>
                </div>
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2" class="p-0">
            <div v-for="(form, i) in forms" :key="i">
              <FormHelper
                ref="formHelper"
                v-model="model[i]"
                :config="config"
                :form="form"
              ></FormHelper>
            </div>
            <FormHelper
              v-model="model"
              :config="config"
              :form="integrationFormAllIntegrations"
            ></FormHelper>
          </v-stepper-content>
          <v-stepper-content step="3" class="p-0">
            <div
              v-html="
                $t(
                  'salesChannelManagementApp.migration.integrationSetupConfirmation'
                )
              "
            ></div>
          </v-stepper-content>
          <v-stepper-content step="4" class="p-0">
            <template v-if="error">
              <h5>
                {{
                  $t("salesChannelManagementApp.migration.finishedErrorText")
                }}
              </h5>
              <p>{{ error }}</p>
            </template>
            <template v-else>
              <div class="mb-4">
                <span v-if="appHandbookLink && appVideoLink">{{
                  $t("salesChannelManagementApp.migration.finishedText")
                }}</span>
                <span v-else>{{
                  $t("salesChannelManagementApp.migration.finishedTextSingular")
                }}</span>
              </div>

              <a
                v-if="appHandbookLink"
                :href="appHandbookLink"
                target="_blank"
                class="btn btn-primary mr-2"
                ><i class="fal fa-book"></i>
                {{ $t("salesChannelManagementApp.helpArea.handbook") }}</a
              >
              <a
                v-if="appVideoLink"
                :href="appVideoLink"
                target="_blank"
                class="btn btn-primary"
                ><i class="fal fa-play"></i>
                {{ $t("salesChannelManagementApp.helpArea.video") }}</a
              >
            </template>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>

    <template #footer>
      <div
        class="d-flex"
        :class="
          step > 1 && step < 4 ? 'justify-space-between' : 'justify-content-end'
        "
      >
        <template v-if="step !== 4">
          <button
            v-if="step > 1"
            :disabled="isMigrating"
            class="btn btn-back"
            size="sm"
            @click="switchStep(step - 1)"
          >
            {{ $t("general.back") }}
          </button>
          <button
            v-if="isLastStep"
            :disabled="isMigrating"
            class="btn btn-primary"
            size="sm"
            @click="startMigration(model)"
          >
            {{
              $t(
                "salesChannelManagementApp.migration.migrateExistingIntegration"
              )
            }}
          </button>
          <button
            v-else
            :disabled="isMigrating || !selectedChannel"
            class="btn btn-primary"
            size="sm"
            @click="switchStep(step + 1)"
          >
            {{ $t("general.next") }}
          </button>
        </template>
        <template v-else>
          <button
            v-if="error"
            class="btn btn-primary"
            size="sm"
            @click="startMigration(model, false, true)"
          >
            {{ $t("salesChannelManagementApp.migration.tryMigrationAgain") }}
          </button>
          <button
            v-else
            class="btn btn-primary"
            size="sm"
            @click="show = false"
          >
            {{ $t("general.close") }}
          </button>
        </template>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.loader {
  height: 100px;
}
.btn-back {
  color: #344054;
  &:disabled {
    opacity: 0.6;
    filter: grayscale(1);
  }

  &:hover {
    color: $color-connect-primary;
    background: $color-connect-primary-light;
  }
}

.migration-help {
  &__buttons {
    gap: 5px;
  }
}
</style>
