<template>
  <div>
    <div v-if="configurations.length">
      <div class="mb-3">
        <div class="mb-1">{{ $t("mapping.availableVariables") }}</div>
        <div class="d-flex align-items-center">
          <button
            v-if="parentFieldType === 'collection'"
            class="btn btn-primary mr-1"
            @click="copyVariable('currentCollectionIndex')"
          >
            {{ $t("mapping.currentCollectionIndex") }}
          </button>
          <div v-if="transformer.config.input.length >= 0">
            <button
              v-for="(variable, i) in transformer.config.input"
              :key="i"
              class="btn btn-primary mr-1"
              @click="copyVariable(variable)"
            >
              {{ variable }}
            </button>
          </div>
        </div>
        <div
          v-if="
            transformer.config.input.length <= 0 &&
            parentFieldType !== 'collection'
          "
          class="text-muted"
        >
          {{ $t("mapping.noAvailableVariables") }}
        </div>
      </div>
      <FormHelper
        ref="form"
        v-model="transformer.config"
        :form="form"
        :config="config"
      />
    </div>
    <div v-else>
      {{ $t("mapping.configurationNoType") }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormHelper from "@/components/Tools/FormHelper/FormHelper";
import { UPDATE_TRANSFORMER } from "@/core/services/store/mappingTransformer.module";
import { checkDepending } from "@/components/Mappings/helpers";

export default {
  components: { FormHelper },
  props: {
    transformer: {
      type: Object,
      default: () => {}
    },
    configurations: {
      type: Array,
      default: () => []
    },
    tabIndex: {
      type: Number,
      default: 0
    },
    parentFieldType: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      form: [],
      config: {
        class: "form-control",
        labelStacked: true,
        snippetPrefix: "formHelper.test",
        enableVariables: false
      }
    };
  },
  computed: {
    ...mapGetters("mapping", { library: "transformers" }),
    ...mapGetters("mapping", ["source", "target"]),
    ...mapGetters("mappingTransformer", ["fieldId", "fieldStructure"])
  },
  mounted() {
    this.setFormFields();
  },
  methods: {
    ...mapActions("mappingTransformer", [UPDATE_TRANSFORMER]),
    copyVariable(variable) {
      navigator.clipboard.writeText("{{" + variable + "}}");
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    setFormFields() {
      //set dynamic form fields
      const configuration = this.configurations;
      const languageShortCode = this.$store.getters.language;
      configuration.forEach(config => {
        let field = {
          label: config.label,
          name: config.name,
          type: config.type === "string" ? "text" : config.type,
          strict: false,
          disableReturnType: true,
          copy: false,
          disabled: config.disabled,
          validations: {
            required: config.required,
            minLength: 1
          },
          ...(config.dependsOn && { dependsOn: config.dependsOn }),
          ...(config.name === "phpeditor" && {
            lang: "php",
            default:
              "<?php\n" +
              "$variable = $input['variable_name']; // this is how you can access your input variables\n" +
              "$return = $variable; // this would return the variable value from the transformer and would be accessible by your configured output variable"
          })
        };
        if (config?.options !== undefined) {
          let options = [];
          for (let optionKey in config.options) {
            let option = config.options[optionKey];
            if (option.label === undefined) {
              options.push(option.value);
            } else {
              options.push({
                value: option.value,
                label: option.label[languageShortCode] ?? option.label
              });
            }
          }
          field.options = options;
        }
        this.form.push(field);
      });
      //set FormHelper structure
      this.data = this.transformer.config;
    },
    checkIfDepends(config) {
      return checkDepending(config, this.configurations);
    }
  }
};
</script>
