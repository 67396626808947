<script setup>
import SettingsForms from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/SettingsForms.vue";
import SkeletonLoading from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/SkeletonLoading.vue";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import DangerZone from "@/components/ExternalApps/SalesChannelManagementApp/components/Forms/DangerZone.vue";
import { computed, ref } from "vue";
import { useStore } from "@/core/services/store";

const { isAppInitialized } = useAppStatus();
const store = useStore();
const settingsAreas = computed(() => {
  const areas = ["wizard"];

  if (store.getters.form("settings")) {
    areas.push("advanced");
  }

  return areas;
});
const selectedSettingsArea = ref(settingsAreas.value[0]);
</script>

<template>
  <div>
    <SkeletonLoading v-if="!isAppInitialized" />

    <div v-else>
      <PerfectScrollbar
        v-if="settingsAreas.length > 0"
        class="d-flex tabs align-end mb-4"
      >
        <div
          v-for="(item, i) in settingsAreas"
          :key="i"
          class="tab-title py-3 px-4"
          :class="{
            active: selectedSettingsArea === item
          }"
          @click="selectedSettingsArea = item"
        >
          <span class="">{{
            $t("salesChannelManagementApp.titles.settings." + item)
          }}</span>
        </div>
      </PerfectScrollbar>

      <SettingsForms :selected-area="selectedSettingsArea">
        <DangerZone />
      </SettingsForms>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tabs {
  border-bottom: 1px solid #d0d5dd;

  .tab-title {
    color: $color-connect-text;
    font-weight: bold;
    border: none;
    cursor: pointer;
    white-space: nowrap;

    &.active,
    &:hover {
      box-shadow: 0 -2px 0 $color-connect-primary inset;
      color: $color-connect-primary;
      text-decoration: none;
      font-weight: bold;
    }

    &.active {
      &:hover {
        color: $color-connect-primary;
      }
    }

    &:hover {
      color: $color-connect-text;
    }
  }
}
</style>
