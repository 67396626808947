<script setup>
import { useTesting } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useTesting";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { computed, ref, defineProps } from "vue";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import Modal from "@/components/Tools/Modal.vue";
import i18n from "@/core/plugins/vue-i18n";

const props = defineProps({
  title: {
    type: String,
    default: null
  },
  buttonText: {
    type: String,
    default: null
  },
  workflowId: {
    type: String,
    default: null
  }
});

const testForm = ref();
const { selectedFeature } = useFeatures();
const { showModal, form, formConfig, formData, testWorkflow } = useTesting(
  testForm,
  props.workflowId
);

const computedTitle = computed(() => {
  if (props.title) {
    return props.title;
  }

  if (selectedFeature.value === "tracking") {
    return i18n.t("salesChannelManagementApp.test.import.tracking");
  }

  return i18n.t("salesChannelManagementApp.test.title", {
    featureName: i18n.t(
      "salesChannelManagementApp.titles." + selectedFeature.value
    )
  });
});
const computedButtonText = computed(
  () =>
    props.buttonText ??
    i18n.t(`salesChannelManagementApp.test.import.${selectedFeature.value}`)
);
const computedModalButtonText = computed(() => {
  if (selectedFeature.value === "tracking") {
    return i18n.t("salesChannelManagementApp.test.export");
  }

  return i18n.t("salesChannelManagementApp.test.start");
});
</script>

<template>
  <div>
    <button class="btn btn-sm btn-primary" @click="showModal = true">
      {{ computedButtonText }}
    </button>

    <Modal
      :key="computedTitle"
      :show-modal="showModal"
      :title="computedTitle"
      @toggle-modal="showModal = $event"
    >
      <template #content>
        <FormHelper
          ref="testForm"
          v-model="formData"
          :config="formConfig"
          :form="form"
        />
      </template>

      <template #footer>
        <button class="btn btn-primary" @click="testWorkflow">
          {{ computedModalButtonText }}
        </button>
      </template>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
i {
  color: $color-connect-text;
}
</style>
