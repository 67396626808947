<template>
  <div :key="codeKey" class="h-100 position-relative">
    <CodeMirror
      ref="codemirror"
      v-model="content"
      :options="computedOptions"
      style="cursor: text; height: 100%"
    />

    <span v-if="showModeBadge" class="mode-badge">{{ mode }}</span>
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/twig/twig";
import "codemirror/mode/xml/xml";
import "codemirror/mode/htmlmixed/htmlmixed";

export default {
  components: { CodeMirror: codemirror },
  props: {
    value: {
      type: String,
      default: ""
    },
    mode: {
      type: String,
      default: ""
    },
    showModeBadge: {
      type: Boolean,
      default: false
    },
    additionalOptions: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      modes: {
        twig: "text/x-twig",
        html: "text/html",
        xml: "application/xml",
        json: "application/json",
        javascript: "application/javascript"
      },
      options: {
        tabSize: 4,
        theme: "material",
        mode: "",
        lineNumbers: true,
        line: true,
        readOnly: false
      },
      codeKey: 0
    };
  },
  computed: {
    content: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("input", value);
      }
    },
    computedOptions: function () {
      return {
        ...this.options,
        ...this.additionalOptions
      };
    }
  },
  mounted() {
    this.options.mode = this.modes[this.mode] ?? this.modes.twig;
  },
  methods: {
    getEditor() {
      return this.$refs.codemirror?.codemirror;
    }
  }
};
</script>

<style lang="scss">
.CodeMirror {
  border-radius: 0.42rem;
  padding: 5px 0;
  height: 100%;
  margin: 0.5rem 1rem 2rem 1rem;
}
</style>

<style lang="scss" scoped>
.mode-badge {
  position: absolute;
  top: 10px;
  right: 20px;
  border-radius: 8px;
  padding: 4px 8px;
  background-color: $color-connect-primary-light;
  color: $color-connect-primary;
}
</style>
