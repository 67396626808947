export const test = {
  requiredFields: "Bitte geben Sie eine Artikelnummer an.",
  open: "Workflow starten",
  start: "Importieren",
  export: "Exportieren",
  title: "{featureName} importieren",
  started: "Der Import wird ausgeführt",
  productNumber: "Artikelnummer",
  orderId: "Auftrags-IDs",
  orderNumber: "Auftragsnummer",
  orderNumberShopify: "Auftragsnummer",
  orderIdTradebyte: "Auftrags-Ids",
  exportCancelledTracking: "Stornierungen exportieren",
  import: {
    salesOrder: "Aufträge importieren",
    tracking: "Auftragsstatus & Tracking exportieren"
  }
};
