<template>
  <div ref="wrapper">
    <div v-if="field.label && !isModal" class="row align-items-center">
      <div class="col">
        <label>
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-popover.hover.top="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
      <div class="col-auto">
        <button class="btn btn-icon" @click="showCodeFullscreen">
          <i class="fal fa-expand"></i>
        </button>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div>
          <FormHelper
            v-model="codeValue"
            :form="form"
            :config="formConfig"
            @change="onChange"
          />
        </div>
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";

export default {
  components: { FormHelper },
  props: {
    field: {
      type: Object
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: "",
      tabSize: 4,
      form: [
        {
          type: "code",
          name: this.field.name,
          disabled: true
        }
      ],
      formConfig: {
        labelStacked: true
      }
    };
  },
  computed: {
    codeValue: {
      get: function () {
        let obj = {};
        obj[this.field.name] = this.field.value;
        return obj;
      },
      set: function (values) {
        this.$set(this.field, "value", values[this.field.name]);
      }
    }
  },
  mounted() {
    this.value = this.field.value ?? "";
  },
  methods: {
    onChange() {
      this.$emit("change");
    },
    showCodeFullscreen() {
      this.$refs["codeFullScreen"].show();
    }
  }
};
</script>
