<script setup>
import Header from "@/components/Tools/Header/Header.vue";
import { computed, ref } from "vue";
import i18n from "@/core/plugins/vue-i18n";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import CustomFunctions from "@/components/CustomFunctions/customFunctions";
import { useRouter } from "vue-router/composables";
import { Error } from "@/core/plugins/swal";
import Form from "@/components/CustomFunctions/Form.vue";

const LOADING_KEY = "loadCustomFunctions";
const router = useRouter();
const formConfig = {
  label: "",
  enableVariables: false,
  snippetPrefix: "customFunctions",
  enableTypecast: false
};
const headerButtons = computed(() => [
  {
    type: "button",
    title: i18n.t("general.save"),
    emit: "save"
  }
]);
const customFunction = ref();

async function onSaveCustomFunction() {
  addEventToLoadingQueue({ key: LOADING_KEY });

  try {
    const response = await CustomFunctions.store(customFunction.value);
    await router.push({
      name: "projectCustomFunctionsEditor",
      params: {
        id: response?.data?.id
      }
    });
  } catch (e) {
    Error(e);
  }

  removeEventFromLoadingQueue({ key: LOADING_KEY });
}
</script>

<template>
  <div>
    <Header
      :title="$t('customFunctions.create').toString()"
      :show-back-button="true"
      :items="headerButtons"
      @back="$router.push({ name: 'projectCustomFunctions' })"
      @save="onSaveCustomFunction"
    />
    <div class="card card-custom">
      <div class="card-body">
        <Form ref="form" v-model="customFunction" :config="formConfig" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
