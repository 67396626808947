export const migration = {
  activeFeatures: "Aktive Features",
  category: "Standardkategorie",
  categoryHelp:
    "Die Kategorie, in die standardmäßig die Artikel geschrieben werden.",
  changeFinishMigrationStatus:
    "Der Status der Migration wurde erfolgreich geändert.",
  changeMigrationActiveStatusTextActive:
    "Achtung! Wenn du den initialer Datenimport deaktivierst, wird der Produktivmodus automatisch aktiviert. Bitte stelle sicher, dass du die Migration beendet hast, da es sonst zu Fehlern kommen kann. Diese Änderung greift erst beim nächsten Speichern.",
  changeMigrationActiveStatusTextInactive:
    "Achtung! Wenn du den initialer Datenimport aktivierst, wird der Produktivmodus automatisch deaktiviert. Bitte stelle sicher, dass keine Workflows mehr im Produktivmodus laufen, da es sonst zu Fehlern kommen kann. Diese Änderung greift erst beim nächsten Speichern.",
  changeMigrationActiveStatusTitle: "Modus der Integration ändern",
  changeMigrationTitle: "Initialer Datenimport",
  createFirstIntegrationTitle: "Keine Integrationen gefunden",
  createFirstIntegrationDescription:
    "Lege eine Integration an um fortzufahren. Du hast die Wahl eine bereits bestehende Integration zu Connect zu migrieren, sofern diese existieren oder eine neue Integration anzulegen.",
  currency: "Währung",
  currencyHelp: "Bitte gib hier die Währung deiner Preise an.",
  development: "Entwicklung",
  ean: "EAN Nr. / Barcode",
  failed: "Es müssen alle Pflichtfelder ausgefüllt werden.",
  finish: "Migration beenden",
  finishDescription: "Möchten Sie wirklich die Migration abschließen?",
  finished: "Die Migration wurde abgeschlossen.",
  grossPrices: "Preise inkl. Steuern (Brutto)",
  grossPricesHelp:
    "Bitte gib hier an, ob du die Artikelpreise im Verkaufskanal inkl. Steuern pflegst.",
  migrationActive: "Initialen Datenimport aktivieren",
  migrationMissing:
    "Diese Integration kann erst verwendet werden, wenn der initiale Datenimport abgeschlossen wurde.",
  migrationStrategy: "Migrationsstrategie",
  migrationStrategyHelp:
    "Um deinen Verkaufskanal mit Xentral zu verbinden, müssen wir die Artikel initial zwischen beiden Systemen verknüpfen. Bitte gib an, welche Situation auf dich zutrifft.",
  name: "Name",
  primary: "Primärschlüssel",
  primaryHelp:
    "Wenn sich Artikel in beiden Systemen (Verkaufskanal & Xentral) befinden, müssen wir wissen, anhand welches Feldes wir die Zuordnung der Artikel machen sollen. Dazu gib bitte hier den Primärschlüssel an, also das Feld in Xentral, welches sich für den Abgleich der Artikel eignet.",
  productive: "Produktiv",
  productNumber: "Artikelnummer",
  shop: "Es befinden sich Artikel nur im Shop",
  shopAndXentral:
    "Es befinden sich Artikel sowohl im Verkaufskanal als auch in Xentral",
  start: "Integration speichern & Initial Import von Artikeln starten",
  started: "Die Migration wurde gestartet.",
  noChannelSelected: "Es wurde keine Integration ausgewählt.",
  noChannelsFound:
    "Es konnten keine Integrationen für die Migration zu Connect gefunden werden.",
  migrateExistingIntegration: "Integration übernehmen",
  tryMigrationAgain: "Anlage der Integration wiederholen",
  selectIntegration: "Welche Integration soll migriert werden?",
  integrationSetupConfirmation:
    "Durch Klicken auf <strong>Integration übernehmen</strong> wird die Migration der Integration abgeschlossen. Um die neue Integration nutzen zu können, beachte bitte folgende Hinweise:<br><br>" +
    "1. Kontrolliere die Auswahl der für die Übertragung ausgewählten Artikel im Tab <strong>Artikelzuordnung</strong>. Du kannst die Filtereinstellungen jederzeit ändern. Die Liste der ausgewählten Artikel findest du im Bereich <strong>Journal</strong>. Nur hier aufgelistete Artikel werden in deinen Verkaufskanal synchronisiert.<br><br>" +
    "2. Bitte kontrolliere die Konfigurationseinstellungen der einzelnen <strong>Workflows</strong>. Wir haben die bereits bestehende Konfiguration automatisch übernommen. Du kannst diese Einstellungen aber jederzeit ändern.<br><br>" +
    "3. Artikel aus Xentral werden anhand der <strong>Artikelnummern</strong> zu den Artikeln im Verkaufskanal zugeordnet. Wenn du Artikel auch updaten, statt nur anlegen willst, stelle bitte <strong>unbedingt</strong> sicher, dass die Artikelnummern aus Xentral mit den Artikelnummern bzw. SKUs aus dem Verkaufskanal übereinstimmen.<br><br>" +
    "4. <strong>Verkaufskanal-spezifische Artikelinformationen werden nicht übernommen</strong>. Bitte stelle sicher, dass deine Artikeldaten für alle deine Online-Shops geeignet sind.<br><br>" +
    "5. Per Voreinstellung wird der Standardpreis des Artikels übertragen. Wenn du einen spezifischen Preis (bspw. eines Kunden oder einer Kundengruppe) an deinen Verkaufskanal übertragen willst, kannst du dies im Bereich <strong>Workflows</strong> im Tab <strong>Preise</strong> einstellen.<br><br>" +
    "6. Um die neue Schnittstelle zu aktivieren, muss für jeden Workflow der <strong>Live-Modus</strong> aktiviert werden. <strong>Bitte achte unbedingt darauf, dass du vorher die alten, noch aktiven Schnittstellen für diesen Workflow deaktivierst, um Konflikte zu vermeiden.</strong>",
  integrationMoreInformation:
    "Weitere hilfreiche Informationen findest du hier:",
  shopifyIntegrationLink:
    'Weitere hilfreiche Informationen findest du <a href="https://help.xentral.com/hc/de/sections/13235038667548-NEW-Shopify-connector-Xentral-Connect-beta" target="_blank">hier</a>.',
  shopware6IntegrationLink:
    'Weitere hilfreiche Informationen findest du <a href="https://help.xentral.com/hc/de/sections/14527994696604-NEW-Shopware-6-connector-Xentral-Connect-beta" target="_blank">hier</a>.',
  woocommerceIntegrationLink:
    'Weitere hilfreiche Informationen findest du <a href="https://help.xentral.com/hc/de/sections/13235026588060-NEW-WooCommerce-connector-Xentral-Connect-beta" target="_blank">hier</a>.',
  formularTitle:
    "Für die Einrichtung der Integration sind folgende Angaben erforderlich:",
  integrationSetupConfirmationTitle: "Wichtige Hinweise",
  video: "Videoanleitung",
  handbook: "Handbuch",
  faq: "FAQ",
  isMigrating:
    "Die Integration wird übernommen. Dies kann einen Moment dauern. Bitte schließe diese Seite nicht.",
  finishedTitle: "Die Integration wurde erfolgreich übernommen",
  finishedText:
    "Ihre Integration wurde erfolgreich erstellt. Unter den folgenden Links findest du weitere Informationen zur Verwendung deiner neuen Integration.",
  finishedTextSingular:
    "Ihre Integration wurde erfolgreich erstellt. Unter dem folgenden Link findest du weitere Informationen zur Verwendung deiner neuen Integration.",
  finishedErrorTitle:
    "Bei der Migration der Integration ist ein Fehler aufgetreten",
  finishedErrorText: "Fehlermeldung:",
  form: {
    salesChannel: "Sales Channel",
    salesChannelHelp:
      "Wähle aus der Liste den Sales Channel, für den du automatisch eine Connect-Integration anlegen möchtest.",
    useExistingToken: "Bestehenden API Token verwenden",
    useExistingTokenHelp:
      "Es wird bereits eine Custom App mit ausreichenden Rechten verwendet. Der bestehende Token kann daher verwendet werden.",
    newToken: "Neuer API Token",
    newTokenHelp:
      "Die neue Shopify Integration benötigt erweiterte Access Scopes. Damit wir deine Instance erfolgreich übernehmen können, nutze eine Custom App mit entsprechenden Berechtigungen.",
    reducedTaxRateCollectionId: "Collection für reduzierten Steuersatz",
    reducedTaxRateCollectionIdHelp:
      'In Shopify werden Artikel über Collections einem reduzierten Steuersatz zugewiesen. Diese Collection muss im Verkaufskanal angelegt und die ID der Collection (z.B. "gid://shopify/Collection/452487217835") hier eingetragen werden.',
    exemptedTaxCollectionId: "Collection für befreiten Steuersatz",
    exemptedTaxCollectionIdHelp:
      'In Shopify werden Artikel über Collections einem befreiten Steuersatz zugewiesen. Diese Collection muss im Verkaufskanal angelegt und die ID der Collection (z.B. "gid://shopify/Collection/452487217835") hier eingetragen werden.',
    id: "Zugangs-ID",
    idHelp:
      'Die Zugangs-ID finden Sie im Shopware 6 Backend unter "Einstellungen > System > Integrationen"',
    secret: "Sicherheitsschlüssel",
    secretHelp:
      'Den Sicherheitsschlüssel finden Sie im Shopware 6 Backend unter "Einstellungen > System > Integrationen". Der Sicherheitsschlüssel wird nur beim Erstellen der Integration angezeigt.',
    selectionStrategy: "Artikelzuordnung",
    selectionStrategyHelp:
      "Wie werden die zwischen Xentral und Verkaufskanal synchronisierten Artikel identifiziert?",
    externalReferences:
      "Artikelzuordnung aus der bestehenden Integration übernehmen",
    ruleBased:
      "Artikel anhand einer definierten Regel (z.B. Projekt, Freifeld etc.) zuordnen",
    syncImages: "Bilderübertragung",
    syncImagesHelp:
      "Achtung: Wenn die Bildübertragung aktiviert ist, müssen alle Bilder in Xentral gepflegt sein. Bilder, die nur im Verkaufskanal und nicht in Xentral vorhanden sind, werden durch die Synchronisierung entfernt."
  },
  projectCreateFailure:
    "Projektanlage fehlgeschlagen. Bitte kontaktiere den Support.",
  migrationSuccessful: "Die Integration wurde erfolgreich übernommen.",
  migrationFailure:
    "Die Migration ist fehlgeschlagen. Bitte kontaktiere den Support.",
  migratedFromSalesChannel:
    'Migriert von Shopimporter "{channelName}". Bitte beende die Konfiguration und aktiviere den Produktivmodus, um die neue Integration zu nutzen.',
  moreInformation: "Mehr Informationen"
};
