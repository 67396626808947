<script setup>
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import Header from "@/components/Tools/Header/Header.vue";
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import { useStore } from "@/core/services/store";
import i18n from "@/core/plugins/vue-i18n";
import CustomFunctions from "@/components/CustomFunctions/customFunctions";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useRouter } from "vue-router/composables";
import { Confirmation, Error } from "@/core/plugins/swal";
import { useCurrentProjectFilter } from "@/composables/useCurrentProjectFilter";

const LOADING_KEY = "loadCustomFunctions";
const LOADING_KEY_DELETE = "deleteCustomFunctions";
const store = useStore();
const router = useRouter();
const toast = getCurrentInstance().proxy.$toast;
const customFunctions = ref();
const customFunctionsMeta = ref();
const isPresetProject = computed(() => store.getters.isPresetProject);
const isDevPresetVersion = computed(() => store.getters.isDevPresetVersion);
const headerButtons = computed(() => {
  let buttons = [
    {
      type: "button",
      title: i18n.t("customFunctions.create"),
      icon: store.getters["config/icons"].add,
      emit: "create"
    }
  ];
  return isPresetProject.value || !isDevPresetVersion.value ? [] : buttons;
});
const fields = computed(() => [
  {
    key: "name",
    label: i18n.t("customFunctions.table.name"),
    sortable: true
  },
  {
    key: "updated_at",
    formatter: "formatDate",
    label: i18n.t("table.updatedAt"),
    sortable: true,
    thStyle: { width: "200px" }
  },
  {
    key: "created_at",
    formatter: "formatDate",
    label: i18n.t("table.createdAt"),
    sortable: true,
    thStyle: { width: "200px" }
  }
]);
const actions = computed(() => [
  {
    key: "editor",
    icon: store.getters["config/icons"].editor,
    emit: "openEditor",
    tooltip: i18n.t("dataStructures.openEditor")
  },
  {
    key: "edit",
    icon: store.getters["config/icons"].edit,
    emit: "edit",
    tooltip: i18n.t("general.edit")
  },
  {
    key: "delete",
    icon: store.getters["config/icons"].delete,
    emit: "delete",
    tooltip: i18n.t("general.delete")
  }
]);

async function loadCustomFunctions(payload) {
  addEventToLoadingQueue({ key: LOADING_KEY });
  let params = { ...payload };
  const { filter } = useCurrentProjectFilter();

  const result = await CustomFunctions.getAll(params, filter.value);

  customFunctions.value = result?.data || [];
  customFunctionsMeta.value = result?.meta || [];

  removeEventFromLoadingQueue({ key: LOADING_KEY });
}

function onOpenEditor(data) {
  router.push({
    name: "projectCustomFunctionsEditor",
    params: { id: data.item.id }
  });
}

function onEdit(data) {
  router.push({
    name: "projectCustomFunctionsEdit",
    params: { id: data.item.id }
  });
}

async function onDelete(data) {
  addEventToLoadingQueue({ key: LOADING_KEY_DELETE });

  const params = {
    title: i18n.t("customFunctions.delete"),
    cancelButtonText: i18n.t("general.cancel"),
    confirmButtonText: i18n.t("general.confirm")
  };

  const response = await Confirmation(params);

  if (!response.isConfirmed) {
    removeEventFromLoadingQueue({ key: LOADING_KEY_DELETE });
    return;
  }

  try {
    await CustomFunctions.delete(data.item.id);
    await loadCustomFunctions();

    toast.fire({
      icon: "success",
      title: i18n.t("customFunctions.deleted", { name: data.item.name })
    });
  } catch (error) {
    Error(error);
  } finally {
    removeEventFromLoadingQueue({ key: LOADING_KEY_DELETE });
  }
}

onMounted(async () => {
  await loadCustomFunctions();
});
</script>

<template>
  <div>
    <Header
      :title="$t('customFunctions.title').toString()"
      :items="headerButtons"
      @create="$router.push({ name: 'projectCustomFunctionsCreate' })"
    />
    <TableHelper
      ref="table"
      :items="customFunctions ?? []"
      :meta="customFunctionsMeta ?? {}"
      :fields="fields"
      :actions="actions"
      :enable-filter="false"
      :filterable="customFunctionsMeta?.filterable ?? {}"
      default-order-by="name"
      :loading-key="LOADING_KEY"
      @reload-data="loadCustomFunctions"
      @openEditor="onOpenEditor"
      @edit="onEdit"
      @delete="onDelete"
    />
  </div>
</template>

<style scoped lang="scss"></style>
