<script setup>
import { computed, defineProps, onMounted, ref, watch, defineEmits } from "vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import i18n from "@/core/plugins/vue-i18n";
import { useStore } from "@/core/services/store";

const store = useStore();
const emit = defineEmits(["input"]);
const props = defineProps({
  value: {
    type: Object,
    default: () => ({})
  },
  config: {
    type: Object,
    default: () => ({})
  }
});
const projects = computed(() => store.getters.projects);
const selectedProject = computed(() => store.getters.selectedProject);
const typeOptions = [
  {
    label: i18n.t("customFunctions.string"),
    value: "string"
  },
  {
    label: i18n.t("customFunctions.number"),
    value: "number"
  },
  {
    label: i18n.t("customFunctions.boolean"),
    value: "boolean"
  },
  {
    label: i18n.t("customFunctions.object"),
    value: "object"
  }
];

const form = computed(() => [
  {
    type: "text",
    name: "name",
    label: "name",
    validations: {
      required: true
    }
  },
  {
    type: "select",
    name: "return_type",
    label: "return_type",
    options: typeOptions,
    validations: {
      required: true
    }
  },
  {
    type: "checkbox",
    name: "return_is_nullable",
    label: "return_is_nullable",
    default: false
  },
  {
    type: "multiselect",
    name: "projects",
    label: "projects",
    options: projects.value?.length
      ? projects.value.map(project => {
          return {
            label: project.name,
            value: project.id
          };
        })
      : [],
    default: [selectedProject.value?.id],
    validations: {
      required: true,
      minLength: 1
    }
  },
  {
    type: "group",
    name: "parameters",
    label: "parameters",
    addGroupLabel: "parametersAdd",
    enableTypecast: false,
    fields: [
      {
        type: "text",
        name: "name",
        label: "parameterName",
        validations: {
          required: true
        }
      },
      {
        type: "select",
        name: "type",
        label: "type",
        options: typeOptions,
        validations: {
          required: true
        }
      },
      {
        type: "text",
        name: "default",
        label: "default"
      },
      {
        type: "checkbox",
        name: "nullable",
        label: "nullable"
      }
    ]
  }
]);
const computedConfig = computed(() => ({
  labelColClass: "col-5",
  fieldColClass: "col-7",
  fieldColClassXl: "col-7",
  ...props.config
}));

const customFunction = ref();

watch(
  () => customFunction.value,
  () => emit("input", customFunction.value),
  {
    deep: true
  }
);

onMounted(() => {
  Object.assign(customFunction.value, props.value);
});
</script>

<template>
  <div>
    <FormHelper
      ref="formHelper"
      v-model="customFunction"
      :form="form"
      :config="computedConfig"
    />
  </div>
</template>

<style scoped lang="scss"></style>
