export const shopware5App = {
  id: 6,
  name: "shopware5",
  label: "Shopware 5",
  showTestConnection: true,
  logo: process.env.BASE_URL + "media/shopLogos/shopware.png",
  areaBlacklist: [
    "workflows",
    "productSelection",
    "wizard.baseData",
    "reporting",
    "journal"
  ],
  additional_params: {
    endpoint: "/api/version",
    authType: "digest"
  },
  environmentVariables: {
    presetId: process.env?.VUE_APP_SCM_APP_SHOPWARE5_PRESET_ID,
    testWorkflows: {
      product: process.env?.VUE_APP_SCM_APP_TEST_PRODUCT_WORKFLOW_ID,
      salesOrder: process.env?.VUE_APP_SCM_APP_SHOPWARE5_TEST_ORDER_WORKFLOW_ID,
      stock: process.env?.VUE_APP_SCM_APP_TEST_STOCK_WORKFLOW_ID,
      prices: process.env?.VUE_APP_SCM_APP_TEST_PRICES_WORKFLOW_ID,
      tracking: process.env?.VUE_APP_SCM_APP_TEST_TRACKING_WORKFLOW_ID,
      category: process.env?.VUE_APP_SCM_APP_TEST_CATEGORY_WORKFLOW_ID
    },
    migrationWorkflows: {
      export: {
        all: process.env
          ?.VUE_APP_SCM_APP_SHOPWARE5_MIGRATION_EXPORT_WORKFLOW_ID,
        single:
          process.env
            ?.VUE_APP_SCM_APP_SHOPWARE5_MIGRATION_SINGLE_EXPORT_WORKFLOW_ID
      },
      import: {
        all: process.env?.VUE_APP_SCM_APP_MIGRATION_IMPORT_WORKFLOW_ID,
        single: process.env?.VUE_APP_SCM_APP_MIGRATION_SINGLE_IMPORT_WORKFLOW_ID
      }
    },
    xentralWorkflowId:
      process.env.VUE_APP_SCM_APP_SHOPWARE5_REPORTING_XENTRAL_WORKFLOW_ID,
    CFLWorkflowId:
      process.env.VUE_APP_SCM_APP_SHOPWARE5_REPORTING_CFL_WORKFLOW_ID,
    CFLDataSetId: process.env.VUE_APP_SCM_APP_SHOPWARE5_REPORTING_CFL_DATASET_ID
  },
  hasOnlyMigration: true
};
