var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.Progressbar),_c('div',{staticClass:"pt-3"},[(_setup.isBusyMount)?_c('b-skeleton-wrapper',[_c('b-skeleton',{staticClass:"mb-4",attrs:{"height":"50px","width":"100%"}}),_vm._l((4),function(n){return _c('b-skeleton',{key:'skeleton-integration-' + n,staticClass:"mb-4",attrs:{"height":"155px","width":"100%"}})})],2):_c('div',[_c(_setup.Header),(_setup.integrationsWithPresets.length > 0)?_c('div',_vm._l((_setup.integrationsWithPresets),function(integration){return _c(_setup.Card,{key:integration.name,staticClass:"mb-4 p-4 integration",class:{
            'integration--error':
              integration.salesChannel?.value?.migrationError?.message
                ?.length > 0 ||
              integration.salesChannel?.value?.migrationError?.length > 0
          }},[_c('div',[_c('div',{staticClass:"mb-2 d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-bold text-h5"},[_vm._v(" "+_vm._s(integration?.name?.replace( _setup.store.getters.selectedApp.name, "" ))+" ")]),_c('div',{staticClass:"status ml-2"},[(integration.productiveMode)?_c('span',{staticClass:"green"},[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.migration.productive"))+" ")]):_c('span',{staticClass:"gray"},[_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.migration.development")))])])]),_c('button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                  _vm.$t('salesChannelManagementApp.titles.settings.title')
                ),expression:"\n                  $t('salesChannelManagementApp.titles.settings.title')\n                ",modifiers:{"hover":true,"top":true}}],staticClass:"btn btn-icon text-h6",on:{"click":function($event){return _setup.selectIntegration(
                    integration,
                    'settings',
                    'settings',
                    'wizard'
                  )}}},[_c('i',{staticClass:"fal fa-gear"})])]),_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-9"},[(
                    Object.keys(integration.featureStatus || {}).length > 0
                  )?_c('div',{class:{
                    'mb-4':
                      !_setup.isMigrationFinished(integration) ||
                      !_setup.isProductsSelected(integration) ||
                      integration.salesChannel?.value?.isMigrated
                  }},[_c('span',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t( "salesChannelManagementApp.migration.activeFeatures" ))+": ")]),_vm._l((integration.featureStatus),function(value,feature){return _c('span',{key:feature,staticClass:"status mb-1"},[_c('span',{class:[value ? 'green' : 'gray']},[_vm._v(_vm._s(_vm.$t("salesChannelManagementApp.titles." + feature)))])])})],2):_vm._e(),(
                    !integration.salesChannel?.value?.migrationError?.message
                      ?.length &&
                    !integration.salesChannel?.value?.migrationError?.length
                  )?[(!_setup.isMigrationFinished(integration))?_c('div',{staticClass:"alert alert-info bg-white mb-0"},[_c('i',{staticClass:"fal fa-warning"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t( "salesChannelManagementApp.migration.migrationMissing" )))])]):(!_setup.isProductsSelected(integration))?_c('div',{staticClass:"alert alert-info bg-white mb-0"},[_c('i',{staticClass:"fal fa-warning"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t( "salesChannelManagementApp.productSelection.productSelectionMissing" )))])]):_vm._e(),(
                      integration.salesChannel?.value?.isMigrated &&
                      !integration.wasAlreadyActivated
                    )?_c('div',{staticClass:"alert alert-info bg-white mb-0"},[_c('i',{staticClass:"fal fa-warning"}),_c('div',{staticClass:"ml-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "salesChannelManagementApp.migration.migratedFromSalesChannel", { channelName: _setup.getChannelInfo(integration)?.bezeichnung || _setup.getChannelInfo(integration)?.name } ))+" ")])])]):_vm._e()]:_c('div',{staticClass:"alert alert-danger bg-white mb-0"},[_c('i',{staticClass:"fal fa-warning"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(integration.salesChannel.value.migrationError?.message ?? (typeof integration.salesChannel.value.migrationError === "string" ? integration.salesChannel.value.migrationError : "")))])])],2),_c('div',{staticClass:"col-auto d-flex flex-column"},[(
                    !integration.salesChannel?.value?.migrationError?.message
                      ?.length &&
                    !integration.salesChannel?.value?.migrationError?.length
                  )?[(
                      _setup.store.getters.appConfig?.hasOnlyMigration ||
                      (_setup.hasMigration && integration.initialDataImportStatus)
                    )?_c('button',{staticClass:"btn btn-outline-primary mb-2",attrs:{"disabled":_setup.isMigrationFinished(integration) && !_setup.hasOnlyMigration},on:{"click":function($event){return _setup.selectIntegration(
                        integration,
                        'migration',
                        'migration',
                        'settings'
                      )}}},[_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(_vm.$t("salesChannelManagementApp.titles.migration.title")))])]):[(
                        !_setup.store.getters?.areaBlacklist?.includes(
                          'productSelection'
                        ) ?? true
                      )?_c('button',{staticClass:"btn btn-outline-primary mb-2",attrs:{"disabled":!_setup.isMigrationFinished(integration)},on:{"click":function($event){return _setup.selectIntegration(
                          integration,
                          'productSelection',
                          'productSelection',
                          'selection'
                        )}}},[_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(_vm.$t( "salesChannelManagementApp.titles.productSelection.selection" )))])]):_vm._e(),(
                        !_setup.store.getters?.areaBlacklist?.includes(
                          'workflows'
                        ) ?? true
                      )?_c('button',{staticClass:"btn btn-outline-primary mb-2",attrs:{"disabled":!_setup.isMigrationFinished(integration) ||
                        !_setup.isProductsSelected(integration)},on:{"click":function($event){return _setup.selectIntegration(integration, 'workflows')}}},[_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(_vm.$t("salesChannelManagementApp.workflows")))])]):_vm._e(),_c('button',{staticClass:"btn btn-outline-primary",attrs:{"disabled":!_setup.isProductsSelected(integration)},on:{"click":function($event){_vm.$router.push({
                          name: 'salesChannelManagementAppJournal'
                        });
                        _setup.selectIntegration(
                          integration,
                          'journal',
                          'journal',
                          '',
                          true
                        );}}},[_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(_vm.$t("salesChannelManagementApp.journal.journal")))])])]]:_c('button',{staticClass:"btn btn-outline-primary mb-2",on:{"click":function($event){return _setup.onOpenError(integration)}}},[_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(_vm.$t("salesChannelManagementApp.migration.moreInformation")))])])],2)])])])}),1):_c('div',[_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c(_setup.Card,{staticClass:"pa-6"},[_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t( "salesChannelManagementApp.migration.createFirstIntegrationTitle" ))+" ")])]),_c('div',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$t( "salesChannelManagementApp.migration.createFirstIntegrationDescription" ))+" ")]),_c('div',{staticClass:"d-flex justify-content-between align-items-end"},[_c('div',{staticClass:"info-card-container"},[_c('div',{staticClass:"info-card d-flex p-4"},[_c('div',{staticClass:"mr-2"},[_c('i',{staticClass:"fal fa-circle-info"})]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('salesChannelManagementApp.help'))}})])]),_c('div',[(
                    (_setup.shopActive || _setup.shopImporterActive) && _setup.channels.length > 0
                  )?_c('button',{staticClass:"btn btn-primary mr-4",on:{"click":function($event){_setup.show = true}}},[_c('i',{staticClass:"fal fa-diagram-next"}),_vm._v(" "+_vm._s(_vm.$t( "salesChannelManagementApp.migration.migrateExistingIntegration" ))+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({ name: 'salesChannelManagementAppWizard' })}}},[_c('i',{staticClass:"fal fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("salesChannelManagementApp.addIntegration"))+" ")])])])])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }