<script setup>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { defineProps } from "vue";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useStore } from "@/core/services/store";
import { useFeatureArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatureArea";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useLoadFormatters } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useLoadFormatters";
import { computed, onMounted, onUnmounted, watch } from "vue";
import { useMigration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigration";
import { useAppStatus } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppStatus";
import {
  addDeactivatedFeature,
  resetDeactivatedFeatures
} from "@/composables/useSalesChannelManagementApp";
import { labelData } from "@/components/ExternalApps/SalesChannelManagementApp/utility/labelData";
import { useWizard } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useWizard";
import i18n from "@/core/plugins/vue-i18n";

defineProps({
  selectedArea: {
    type: String,
    required: true
  }
});

const { onFeaturesChanged } = useFeatures();
const { selectedIntegration, formValueChange } = useIntegration();
const { onFeaturesInput } = useFeatureArea();
const { loadFormatter } = useLoadFormatters();
const store = useStore();
const { hasOnlyMigration, hasMigration } = useAppStatus();
const {
  onChangeMigrationStatus,
  migrationChecks,
  migrationStatus,
  migrationStatusForm
} = useMigration();

onMounted(() => {
  // Quick Fix -> disable the change of the name field
  const nameField = store.getters
    .form("wizard.baseData")
    .find(field => field.name === "name");
  nameField.type = "hidden";
  nameField.disabled = true;

  if (!hasMigration.value || hasOnlyMigration.value) return;

  migrationStatus.value.active =
    !migrationChecks.value?.value[selectedIntegration.value.id]
      ?.migrationFinished;

  toggleActiveFieldDisableStatus();
});

const wizardCredentials = computed(() => {
  const credentialsForm = store.getters.form("wizard.credentials");

  const showTestConnection =
    store.getters.appConfig?.showTestConnection ?? false;

  if (!showTestConnection) {
    return credentialsForm;
  }

  const { validateCredentials } = useWizard(selectedIntegration);
  const validateCredentialsAction = {
    type: "action",
    action: validateCredentials,
    label: i18n.t("salesChannelManagementApp.validateCredentials")
  };

  return [...credentialsForm, validateCredentialsAction];
});

watch(
  () => migrationStatus.value.active,
  () => {
    toggleActiveFieldDisableStatus();
  }
);

const unwatchFeatureChange = watch(
  () => selectedIntegration.value.features.value,
  () => {
    resetDeactivatedFeatures();

    for (const [key, value] of Object.entries(
      selectedIntegration.value.features.value
    )) {
      if (value !== false || !selectedIntegration.value?.[key]?.baseSettings)
        continue;

      addDeactivatedFeature(key);
    }
  }
);

function toggleActiveFieldDisableStatus() {
  let activeField = store.getters
    .form("wizard.baseData")
    .find(field => field.name === "active");

  if (!activeField || !hasMigration.value || hasOnlyMigration.value) return;
  activeField.disabled = migrationStatus.value.active;
}

onUnmounted(() => {
  unwatchFeatureChange();
});
</script>

<template>
  <div>
    <template v-if="selectedArea === 'wizard'">
      <template v-if="selectedIntegration">
        <FormHelper
          v-if="
            selectedIntegration.id in (migrationChecks?.value ?? []) &&
            !hasOnlyMigration &&
            hasMigration
          "
          v-model="migrationStatus"
          :form="migrationStatusForm"
          :config="{
            title: $t(
              'salesChannelManagementApp.migration.changeMigrationTitle'
            ),
            snippetPrefix: 'salesChannelManagementApp',
            labelColClass: 'col-5',
            fieldColClass: 'col-7',
            fieldColClassXl: 'col-7',
            loadingGroup: 'salesChannelManagementApp',
            ...(labelData ?? {})
          }"
          @change="onChangeMigrationStatus($event)"
        />

        <FormHelper
          v-if="
            selectedIntegration.baseData &&
            (!store.getters?.areaBlacklist?.includes('wizard.baseData') ?? true)
          "
          v-model="selectedIntegration.baseData.value"
          :form="store.getters.form('wizard.baseData')"
          :config="{
            title: $t('salesChannelManagementApp.livemode'),
            snippetPrefix: 'salesChannelManagementApp',
            labelColClass: 'col-5',
            fieldColClass: 'col-7',
            fieldColClassXl: 'col-7',
            loadingGroup: 'salesChannelManagementApp',
            ...(labelData ?? {})
          }"
          @change="formValueChange($event, 'baseData')"
        />
        <FormHelper
          v-if="
            store.getters.form('wizard.credentials') &&
            selectedIntegration.credentials
          "
          v-model="selectedIntegration.credentials.value"
          :form="wizardCredentials"
          :config="{
            title: $t('salesChannelManagementApp.credentials'),
            snippetPrefix: 'salesChannelManagementApp',
            labelColClass: 'col-5',
            fieldColClass: 'col-7',
            fieldColClassXl: 'col-7',
            loadingGroup: 'salesChannelManagementApp',
            ...(labelData ?? {})
          }"
          @change="formValueChange($event, 'credentials')"
        />
        <FormHelper
          v-if="
            store.getters.form('wizard.tbOneChannelSelection') &&
            selectedIntegration.tbOneChannelSelection
          "
          v-model="selectedIntegration.tbOneChannelSelection.value"
          :config="{
            title: $t('salesChannelManagementApp.titles.tbOneChannelSelection'),
            snippetPrefix: 'salesChannelManagementApp',
            labelColClass: 'col-5',
            fieldColClass: 'col-7',
            fieldColClassXl: 'col-7',
            loadingGroup: 'salesChannelManagementApp',
            ...(labelData ?? {})
          }"
          :form="store.getters.form('wizard.tbOneChannelSelection')"
          @change="formValueChange($event, 'tbOneChannelSelection')"
        />
        <FormHelper
          v-if="
            store.getters.form('wizard.sftpData') &&
            selectedIntegration.sftpData
          "
          v-model="selectedIntegration.sftpData.value"
          :config="{
            title: $t('salesChannelManagementApp.titles.sftpData'),
            snippetPrefix: 'salesChannelManagementApp',
            labelColClass: 'col-5',
            fieldColClass: 'col-7',
            fieldColClassXl: 'col-7',
            loadingGroup: 'salesChannelManagementApp',
            ...(labelData ?? {})
          }"
          :form="store.getters.form('wizard.sftpData')"
          @change="formValueChange($event, 'sftpData')"
        />
        <FormHelper
          v-if="
            store.getters.form('wizard.features') &&
            selectedIntegration.features
          "
          v-model="selectedIntegration.features.value"
          :form="store.getters.form('wizard.features')"
          :config="{
            title: $t('salesChannelManagementApp.features'),
            snippetPrefix: 'salesChannelManagementApp',
            labelColClass: 'col-5',
            fieldColClass: 'col-7',
            fieldColClassXl: 'col-7',
            loadingGroup: 'salesChannelManagementApp',
            ...(labelData ?? {})
          }"
          @change="
            event => {
              onFeaturesChanged(event);
              formValueChange(event, 'features');
            }
          "
          @input="onFeaturesInput"
        />
      </template>

      <slot></slot>
    </template>

    <template v-else>
      <FormHelper
        v-for="(form, key) in store.getters.form('settings')"
        :key="'advanced-settings-' + key"
        v-model="selectedIntegration.settings[key].value"
        class="form-helper-settings"
        :form="form || {}"
        :config="{
          snippetPrefix: 'salesChannelManagementApp',
          title: $t('salesChannelManagementApp.titles.settings.' + key),
          configValues: false,
          customVariables: ['scmaXentralFields'],
          labelStacked: selectedArea === 'productAssignment',
          ...(labelData ?? {}),
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp'
        }"
        @change="formValueChange($event, 'settings', key)"
        @load-formatter="loadFormatter"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.form-helper {
  border-bottom: 1px solid #d0d5dd;
  padding: 1rem 0 2rem 0;
  margin: 1rem 0;

  &-settings {
    &:last-of-type {
      border-bottom: none;
    }
  }
}
</style>
