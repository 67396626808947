import { computed, ref } from "vue";
import { useArea } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useArea";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import _ from "lodash";
import { useStore } from "@/core/services/store";

export const useFeatureArea = () => {
  const { getAreas, selectedArea } = useArea();
  const { selectedFeature, features } = useFeatures();
  const { selectedIntegration } = useIntegration();
  const featureAreas = computed(() => getAreas(selectedFeature.value));
  const store = useStore();
  /**
   * select a specific area
   * @param area
   */
  function selectArea(area) {
    selectedArea.value = area;

    if (
      !selectedIntegration.value?.[selectedFeature.value]?.[
        selectedArea.value
      ] &&
      selectedArea.value !== "wizard"
    ) {
      addArea();
    }
  }

  /**
   * If the current area doesn't exist inside the selected feature we have to add it
   */
  function addArea() {
    const name = selectedFeature.value + _.upperFirst(selectedArea.value);

    selectedIntegration.value[selectedFeature.value][selectedArea.value] = ref({
      name: name,
      label: name,
      type: "json",
      project_id: selectedIntegration.value.id,
      value: {}
    }).value;
  }

  /**
   * Set the selected feature to migration and unset the selected area, because the migration only has one step
   */
  function selectMigration() {
    selectedFeature.value = "migration";
    selectedArea.value = "settings";

    if (!selectedIntegration.value.migration) {
      const migrationValues = ref({});

      for (const migrationArea of Object.keys(
        store.getters.form("migration")
      )) {
        migrationValues.value[migrationArea] = ref({}).value;
      }

      selectedIntegration.value.migration = ref({
        name: "migration",
        label: "migration",
        type: "json",
        project_id: selectedIntegration.value.id,
        value: migrationValues.value
      }).value;
    }
  }

  /**
   * select a specific feature
   * @param feature
   */
  function selectFeature(feature) {
    if (feature === selectedFeature.value) return;

    selectedFeature.value = feature;

    disableDependingOnFeatureStatus();
  }

  function onFeaturesInput() {
    if (
      features.value.includes(selectedFeature.value) ||
      selectedFeature.value === "settings"
    ) {
      return;
    }
    // select first feature
    const feature = features.value[0] ?? null;
    selectFeature(feature);
  }

  /**
   * enable or disable the form fields, depending on the status of the selected feature
   */
  function disableDependingOnFeatureStatus() {
    const testForm = store.getters.form("app." + selectedFeature.value);
    const currentFeatureStatus =
      selectedIntegration.value?.[selectedFeature.value]?.baseSettings?.value
        ?.active;

    for (const area of Object.values(testForm || {})) {
      for (const field of Object.values(area || {})) {
        if (field?.setDisabled) {
          const { dependsOnFeatureStatus } = field.setDisabled;

          field.disabled = dependsOnFeatureStatus === currentFeatureStatus;
        }
      }
    }
  }

  return {
    featureAreas,
    selectArea,
    onFeaturesInput,
    selectFeature,
    selectMigration,
    disableDependingOnFeatureStatus,
    selectedArea,
    selectedFeature
  };
};
