export const customFunctions = {
  title: "Custom Functions",
  create: "Create Custom Function",
  name: "Name",
  parameterName: "Name",
  return_type: "Data Type",
  return_is_nullable: "Null",
  type: "Data Type",
  default: "Default Value",
  nullable: "Null",
  string: "String",
  number: "Number",
  boolean: "Boolean",
  object: "Object",
  projects: "Projects",
  parameters: "Parameters",
  parametersAdd: "Add Parameter",
  table: {
    name: "Name"
  },
  insertVariable: 'Insert parameter "{name}" at the current position',
  edit: 'Edit Custom Function "{name}"',
  variables: "Parameters",
  saved: "Custom Function successfully saved",
  test: "Test Custom Function",
  testExecute: "Test",
  delete: "Delete Custom Function?",
  deleted: 'The Custom Function "{name}" was successfully deleted',
  tested: "Successfully tested",
  testResult: "Preview",
  parametersHelp:
    "You can click on the parameters to insert the selected parameter at the current position.",
  error: "An error occurred while executing the JavaScript code: {error}"
};
