export const reporting = {
  categorySync: "Kategorien synchronisieren",
  categorySyncDescription:
    'Mit Klick auf "Kategorie-Synchronisierung starten" löst du einmalig die Übertragung aller Kategorien von Xentral zum Verkaufskanal aus. Dabei werden die Einstellungen verwendet, die du im Bereich "Workflows" im Reiter "Kategorie" konfiguriert hast.',
  categorySyncFinished: "Kategorie-Synchronisierung gestartet",
  categorySyncStart: "Kategorie-Synchronisierung starten",
  executeCategorySync: "Soll die Kategorie-Synchronisierung gestartet werden?",
  comment: "Kommentar",
  destination: "Ziel",
  detail: "Übersicht der verarbeiteten Artikeldaten",
  entities: {
    default: "Element",
    product: "Stammdaten",
    salesOrder: "Auftrag",
    stock: "Bestand",
    prices: "Preis",
    categories: "Kategorien",
    customFields: "Freifelder",
    properties: "Eigenschaften",
    crossSeller: "Cross-selling Artikel",
    media: "Bilder",
    type: "Typ",
    state: "Status",
    lastRun: "Letzter Durchlauf",
    orderDate: "Bestelldatum",
    tracking: "Tracking"
  },
  entity: "Datentyp",
  error: "Ein Fehler ist aufgetreten.",
  executed: {
    salesOrder: "Auftrag wird importiert",
    tracking: "Auftragsstatus & Tracking wird exportiert"
  },
  executeMigrationWorkflow: "{action} mit diesem Artikel ausführen.",
  export: "Artikel in Verkaufskanal identifizieren",
  finish: "Migration beenden",
  firstIteration: "Erster Durchlauf",
  id: "ID",
  id_dynamic: "Dynamische Auftrags-ID",
  import: "Artikel zu Xentral importieren",
  isVariant: "Variante",
  trackingExported: "Tracking exportiert",
  lastIteration: "Letzter Durchlauf",
  last_read: "Zuletzt für den Export vorbereitet",
  last_write: "Zuletzt in den Verkaufskanal exportiert",
  legend: {
    reset: "Zurücksetzen",
    process: {
      pending: "Ausstehend",
      working: "In Arbeit",
      stopped: "Erfolgreich",
      error: "Fehler"
    }
  },
  migrationExportNotFinished:
    "Es müssen noch Artikeldaten aus dem Verkaufskanal exportiert werden.",
  migrationImportNotFinished:
    "Es müssen noch Artikeldaten in Xentral importiert werden.",
  migrationSingleStart: "Für einzelnen Artikel starten",
  migrationStart: "Für alle Artikel starten",
  noStatusFound: {
    last_read: "Dieser Datentyp wurde noch nicht für den Export vorbereitet.",
    last_write:
      "Dieser Datentyp wurde noch nicht in den Verkaufskanal exportiert."
  },
  open: "Reporting öffnen",
  orderNumber: "Auftragsnummer",
  orderId: "Auftrags-ID",
  orderDate: "Bestelldatum",
  pricesSync: "Preis synchronisieren",
  pricesSyncDescription:
    'Mit Klick auf "Preis-Synchronisierung starten" löst du einmalig die Übertragung des Preises des ausgewählten Artikels von Xentral zum Verkaufskanal aus. Dabei werden die Einstellungen verwendet, die du im Bereich "Workflows" im Reiter "Preise" konfiguriert hast.',
  pricesSyncDescriptionBatch:
    'Mit Klick auf "Preis-Synchronisierung starten" löst du einmalig die Übertragung des Preises der ausgewählten Artikel von Xentral zum Verkaufskanal aus. Dabei werden die Einstellungen verwendet, die du im Bereich "Workflows" im Reiter "Preise" konfiguriert hast.',
  pricesSyncFinished: "Preis-Synchronisierung erfolgreich beendet",
  pricesSyncStart: "Preis-Synchronisierung starten",
  process: {
    aborted: {
      last_read: "Die Vorbereitung für den Export wurde abgebrochen",
      last_write: "Der Export in den Verkaufskanal wurde abgebrochen."
    },
    error: {
      last_read: "Beim Vorbereiten des Exports ist ein Fehler aufgetreten.",
      last_write: "Beim Export in den Verkaufskanal ist ein Fehler aufgetreten."
    },
    pending: {
      last_read:
        "Die Vorbereitung für den Export dieses Datentyps steht kurz bevor.",
      last_write:
        "Der Export in den Verkaufskanal für diesen Datentyp steht kurz bevor."
    },
    started: {
      last_read: "Die Vorbereitung für den Export wurde gestartet.",
      last_write: "Der Export in den Verkaufskanal wurde gestartet."
    },
    stopped: {
      last_read: "Der Datentyp wurde erfolgreich für den Export vorbereitet.",
      last_write:
        "Der Datentyp wurde erfolgreich in den Verkaufskanal exportiert."
    },
    waiting: {
      last_read: "Dieser Datentyp wird gerade für den Export vorbereitet.",
      last_write: "Dieser Datentyp wird gerade in den Verkaufskanal exportiert."
    },
    warning: {
      last_read:
        "Der Datentyp wurde teilweise erfolgreich für den Export vorbereitet.",
      last_write:
        "Der Datentyp wurde teilweise erfolgreich in den Verkaufskanal exportiert."
    },
    working: {
      last_read: "Dieser Datentyp wird gerade für den Export vorbereitet.",
      last_write: "Dieser Datentyp wird gerade in den Verkaufskanal exportiert."
    }
  },
  product: "Artikel-Reporting",
  productId: "Artikel-ID",
  productNumber: "Artikelnummer",
  productSync: "Artikel synchronisieren",
  productSyncDescription:
    'Mit Klick auf "Artikel-Synchronisierung starten" löst du einmalig die Übertragung des ausgewählten Artikels von Xentral zum Verkaufskanal aus. Dabei werden die Einstellungen verwendet, die du im Bereich "Workflows" im Reiter "Artikel" konfiguriert hast.',
  productSyncDescriptionBatch:
    'Mit Klick auf "Artikel-Synchronisierung starten" löst du einmalig die Übertragung der ausgewählten Artikel von Xentral zum Verkaufskanal aus. Dabei werden die Einstellungen verwendet, die du im Bereich "Workflows" im Reiter "Artikel" konfiguriert hast.',
  productSyncFinished: "Artikel-Synchronisierung gestartet",
  productSyncStart: "Artikel-Synchronisierung starten",
  requeueJob: "Erneut ausführen",
  requeueJobs: "Erneut ausführen",
  settings: "Einstellungen",
  showDetail: "Verlauf anzeigen",
  source: "Quelle",
  state: "Status",
  states: {
    imported: "{entity} in Xentral importiert",
    importStarted: "Import der {entity} ausstehend",
    exportStarted: "{entity} im Verkaufskanal erkannt",
    exported: "{entity} zu Connect importiert",
    stateExportStarted: "Export des Status gestartet",
    statePartiallyExported: "Status-Update ausstehend",
    trackingExportCheck: "Status-Update ausstehend",
    stateExportAborted: "Status-Update ausstehend",
    stateExported: "Status-Update ausstehend",
    stateImportStarted: "Status-Update ausstehend",
    statePartiallyImported:
      "Auftragsstatus „teilweise versendet“ in den Verkaufskanal exportiert",
    stateImported: "Auftragsstatus „versendet“ in den Verkaufskanal exportiert",
    noStateUpdate: "Status-Update ausstehend",
    importFailed: "Der Import der {entity} ist fehlgeschlagen.",
    exportFailed: "Der Export der {entity} zu Connect ist fehlgeschlagen",
    salesOrderError: "Status-Update ausstehend",
    stateError: "Status-Update fehlgeschlagen",
    salesOrder: {
      stateExportStarted: "Export des Status gestartet",
      statePartiallyExported: "Status-Update ausstehend",
      trackingExportCheck: "Status-Update ausstehend",
      stateExportAborted: "Status-Update ausstehend",
      stateExported: "Status-Update ausstehend",
      stateImportStarted: "Status-Update ausstehend",
      statePartiallyImported:
        "Auftragsstatus „teilweise versendet“ in den Verkaufskanal exportiert",
      stateImported:
        "Auftragsstatus „versendet“ in den Verkaufskanal exportiert",
      imported: "Auftrag in Xentral importiert",
      importStarted: "Import des Auftrags ausstehend",
      exportStarted: "Auftrag im Verkaufskanal erkannt",
      exported: "Auftrag zu Connect importiert",
      importFailed: "Der Import des Auftrags ist fehlgeschlagen.",
      exportFailed: "Der Export des Auftrags zu Connect ist fehlgeschlagen",
      stateImportFailed: "Der Import des Auftragsstatus ist fehlgeschlagen.",
      stateExportFailed:
        "Der Export des Auftragsstatus zu Connect ist fehlgeschlagen",
      noStateUpdate: "Status-Update ausstehend",
      salesOrderError: "Der Import des Auftrags ist fehlgeschlagen",
      stateError: "Status-Update fehlgeschlagen",
      cancelled: "Auftragsstatus „storniert“ in den Verkaufskanal exportiert"
    },
    product: {
      imported: "Artikel in Xentral importiert",
      importStarted: "Import des Artikels ausstehend",
      exportStarted: "Artikel im Verkaufskanal erkannt",
      exported: "Artikel zu Connect importiert",
      importFailed: "Der Import der Artikel ist fehlgeschlagen.",
      exportFailed: "Der Export der Artikel zu Connect ist fehlgeschlagen"
    }
  },
  statusTableTitle: "Status der letzten",
  statusTableTitleRange: "Status von {firstDate} bis {lastDate}",
  stockSync: "Bestand synchronisieren",
  stockSyncDescription:
    'Mit Klick auf "Bestand-Synchronisierung starten" löst du einmalig die Übertragung des Bestands des ausgewählten Artikels von Xentral zum Verkaufskanal aus. Dabei werden die Einstellungen verwendet, die du im Bereich "Workflows" im Reiter "Bestand" konfiguriert hast.',
  stockSyncDescriptionBatch:
    'Mit Klick auf "Bestand-Synchronisierung starten" löst du einmalig die Übertragung des Bestands der ausgewählten Artikel von Xentral zum Verkaufskanal aus. Dabei werden die Einstellungen verwendet, die du im Bereich "Workflows" im Reiter "Bestand" konfiguriert hast.',
  stockSyncFinished: "Bestand-Synchronisierung erfolgreich beendet",
  stockSyncStart: "Bestand-Synchronisierung starten",
  syncPrices: "Preise synchronisieren",
  syncProducts: "Artikel synchronisieren",
  syncStocks: "Bestände synchronisieren",
  timeRange: {
    days: {
      1: "24 Stunden",
      2: "48 Stunden",
      7: "7 Tage",
      14: "14 Tage",
      0: "Eigener Datumsbereich"
    }
  },
  title: "Reporting {featureName}",
  totalIterations: "Anzahl der Durchläufe",
  updated_at: "Aktualisierungsdatum",
  processIterationDeletionNotice:
    "Bitte beachte, dass Fehlermeldungen nach 14 Tagen nicht mehr angezeigt.",
  trackingAdded: "Tracking",
  syncOffers: "Angebote synchronisieren",
  offerSync: "Angebote synchronisieren",
  offerSyncDescriptionBatch:
    'Mit Klick auf "Angebots-Synchronisierung starten" löst du einmalig die Übertragung der Bestände und Preise ausgewählten Artikel von Xentral zum Verkaufskanal aus. Dabei werden die Einstellungen verwendet, die du im Bereich "Workflows" im Reiter "Angebote" konfiguriert hast.',
  offerSyncStart: "Angebots-Synchronisierung starten",
  offerSyncFinished: "Angebots-Synchronisierung erfolgreich beendet"
};
